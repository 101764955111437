import styled from "styled-components";

export const TransactionsContainer = styled.div`
  padding-left: 10px;
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  position: relative;
  left: 20px;
  & .fixlwq .ant-tree .ant-tree-switcher-leaf-line::before {
    height: 77px;
  }

  & svg {
    color: #1645b8;
  }
`;
