import React, { useEffect, useState } from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns, useSelectableRow } from "./usersTableConfig";
import Button from "../../../components/dashboard-layout/button/Button";
import { Link } from "react-router-dom";
import {
  getOrganizationUsers,
  getUserInvitations,
} from "../../../Services/firebase";
import usePagination from "../../../hooks/usePagination";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import UserForm from "./UserForm";
import { Radio } from "antd";

export default function Users() {
  const rowSelectable = useSelectableRow();
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
    resetPagination,
  } = usePagination();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const modal = useDashboardModal();
  const [invited, setInvited] = useState(false);

  useEffect(() => {
    const onGetOrganizationUsers = async () => {
      try {
        setLoading(true);
        const { data } = await getOrganizationUsers(
          page,
          itemsPerPage,
          invited
        );
        const users = data.users;
        const formattedUsers = users?.map((user, i) => ({
          id: i,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
          email: user?.email,
          user_type: user?.type?.split("_").join(" ").toLowerCase(),
        }));
        setPagination(data.pagination);
        setUsers(formattedUsers);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    onGetOrganizationUsers();
  }, [itemsPerPage, page, setPagination, invited]);

  return (
    <>
      <DashboardSectionTitle>Usuarios</DashboardSectionTitle>
      <Radio.Group defaultValue="ALL" buttonStyle="solid">
        <Radio.Button
          value="ALL"
          onClick={() => {
            setInvited(false);
            resetPagination();
          }}
        >
          Usuarios
        </Radio.Button>
        <Radio.Button
          value="INVITED"
          onClick={() => {
            setInvited(true);
            resetPagination();
          }}
        >
          Usuarios invitados
        </Radio.Button>
      </Radio.Group>
      <DashboardTable
        columns={columns}
        rowSelectable={rowSelectable}
        tableTitle="Lista de Usuarios"
        buttonActionText="+ Crear transportador"
        rowkey="id"
        loading={loading}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItemsPerPage}
        data={users}
        pagination={pagination}
        customButton={
          <>
            <Button type="primary" actionButton={modal.showModal}>
              + Crear usuario
            </Button>
          </>
        }
      />
      <UserForm modalSetting={{ ...modal, title: "Agregar un usuario" }} />
    </>
  );
}
