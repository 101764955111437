import React, { useState, useEffect } from "react";
import Button from "../../../components/dashboard-layout/button/Button";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";
import { Row, Col } from "antd";
import { UploadReceiptLayout, DatePickerLayout } from "./styled-components";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { InputLayout } from "../../../components/dashboard-layout/styledComponents";
import { Controller, useForm } from "react-hook-form";
import CurrencyFormat from "react-currency-format";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { addEfectyPayment } from "../../../Services/firebase";
import { toast } from "react-toastify";

const getBase64File = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader(file);
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function AddPaymentForm({
  modalSetting,
  setFormSuccess,
  formSuccess,
}) {
  const {
    control,
    errors,
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm();
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    const payment = {
      date: data.date.format(),
      image: file,
      value: Number(data.value),
    };
    onAddEfectyPayment(payment);
  };
  const handleClearInputFile = () => {
    setValue("voucher", "");
    setFileName(null);
  };
  const watchVoucher = watch(["voucher"]);
  const handleFileChange = async (files) => {
    if (files[0]) {
      const name = files[0].name;
      setFileName(name);
      const file64base = await getBase64File(files[0]);
      setFile(file64base);
    }
  };
  const onAddEfectyPayment = async (data) => {
    setLoading(true);
    try {
      await addEfectyPayment(data.date, data.value, data.image, 1);
      setLoading(false);
      toast.success("Se ha registrado el pago exitosamente");
      modalSetting.handleCancel();
      setValue("value", "");
      setValue("date", "");
      setValue("voucher", "");
      setFormSuccess(!formSuccess);
    } catch (error) {
      setLoading(false);
      toast.error(error.message || "Ha ocurrido un error inesperado.");
    }
  };
  useEffect(() => {
    if (watchVoucher.voucher) {
      handleFileChange(watchVoucher.voucher);
    }
  }, [watch]);
  return (
    <DashboardModal {...modalSetting} footer={null}>
      <p>Para agregar un pago por favor ingresa los siguientes campos:</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col span={12}>
            <Controller
              as={DatePickerLayout}
              placeholder="dd-mm-yyyy"
              format="DD-MM-YYYY"
              name={"date"}
              control={control}
              renderExtraFooter={() => "Selecciona una fecha"}
              rules={{
                required: "Este campo es requerido.",
              }}
            />
            <ErrorMessage error={errors.date}>
              {errors?.date?.message}
            </ErrorMessage>
          </Col>
          <Col span={12}>
            <Controller
              name={"value"}
              type="text"
              control={control}
              placeholder="$0.00"
              rules={{
                required: "Este campo es requerido.",
              }}
              render={(props) => {
                return (
                  <CurrencyFormat
                    value={getValues("value")}
                    customInput={InputLayout}
                    thousandSeparator={true}
                    prefix={"$"}
                    placeholder="$0.00"
                    onValueChange={(values) => {
                      const { value } = values;
                      props.onChange(value);
                    }}
                  />
                );
              }}
            />
            <ErrorMessage error={errors.value}>
              {errors?.value?.message}
            </ErrorMessage>
          </Col>
        </Row>
        <Row>
          <label>Adjuntar comprobante:</label>
          <UploadReceiptLayout>
            <input
              accept=".png,.jpg,application/pdf"
              type="file"
              name="voucher"
              ref={register({ required: true })}
            />
            {!fileName ? (
              <div className="upload-receipt-component">
                <p>
                  Arrastre y suelte su archivo aquí o presione el botón para
                  cargar
                </p>
                <button>Adjuntar archivo</button>

                <span>
                  Solo .png .jpg . Pdf con un tamaño max del archivo de 2MB
                </span>
              </div>
            ) : (
              <div className="upload-receipt-component">
                <p>
                  Arrastre y suelte su archivo aquí o presione el botón para
                  cargar
                </p>
                <p>{fileName}</p>
                <button onClick={handleClearInputFile} className="unmount-file">
                  Borrar
                </button>
                <span>
                  Solo .png .jpg . Pdf con un tamaño max del archivo de 2MB
                </span>
              </div>
            )}
          </UploadReceiptLayout>
          <ErrorMessage error={errors.voucher}>
            Este campo es requerido.
          </ErrorMessage>
        </Row>
        <Row justify="end" style={{ marginTop: 20 }}>
          {!loading ? (
            <>
              <Button
                type="secondary"
                actionButton={() => modalSetting.handleCancel()}
              >
                Cancelar
              </Button>
              <Button type="submit">Aceptar pago</Button>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 200,
              }}
            >
              <Spinner />
            </div>
          )}
        </Row>
      </form>
    </DashboardModal>
  );
}
