import React from "react";
import { HeaderLayout } from "../styledComponents";
import { MdMenu } from "react-icons/md";
import ToolbarHeader from "../toolbar/ToolbarHeader";

export default function Header({ handleMenuClick, history }) {
  return (
    <HeaderLayout style={{}}>
      <MdMenu onClick={handleMenuClick} size={25}  />
      {/* <SearchBar
        pholder={"Buscar por solicitud, transportador, factura…"}
        style={{ flexGrow: 3 }}
        elmwidth="100%"
      /> */}
      <ToolbarHeader style={{ flexGrow: 2 }} history={history}/>
    </HeaderLayout>
  );
}
