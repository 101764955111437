import React, { useState, useContext, useEffect } from "react";
import {
  DashboardSectionTitle,
  DashboardCard,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import { Row, Col } from "antd";
import Button from "../../../components/dashboard-layout/button/Button";
import Spinner from "../../../components/dashboard-SA/Spinner";
import Logo from "../../../components/auth-layout/logo/logo";
import Image from "../../../assets/img/terpel.png";
import { FiLogOut } from "react-icons/fi";
import { Context } from "../../../Context";
import { MdModeEdit } from "react-icons/md";
import Box from "../../../components/Box";
import { onGetAllyProfile } from "../../../Services/firebase";
import AllyProducts from "../../../containers/dashboard-sa/Allies/AllyProducts";

export default function AlliedProfile() {
  const { removeAuth } = useContext(Context);
  const [ally, setAlly] = useState(null);
  const [loading, setLoading] = useState(false);
  const getAllyData = async () => {
    setLoading(true);
    try {
      const response = await onGetAllyProfile();
      setAlly(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllyData();
  }, []);
  return (
    <>
      <FlexContainerHelper justify="space-between">
        <DashboardSectionTitle>Perfil</DashboardSectionTitle>
      </FlexContainerHelper>
      <DashboardCard direction={"column"}>
        {!loading && (
          <Row>
            <Col
              span={6}
              style={{
                borderRight: "solid 1px #e3e3e3",
                minHeight: "30vh",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display="flex" justifyContent="center">
                <Logo
                  imageSource={ally?.urlProfileImage || Image}
                  altText={ally?.name}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button type="secondary">
                  <MdModeEdit size={18} /> Editar Imagen
                </Button>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button type="red_express" actionButton={removeAuth}>
                  <FiLogOut size={18} /> Cerrar sesión
                </Button>
              </Box>
            </Col>
            <Col
              span={18}
              style={{
                minHeight: "30vh",
                overflow: "hidden",
                paddingLeft: "30px",
              }}
            >
              <div>
                <Row>
                  <Col span={6}>
                    <div style={{ color: "#767676", marginTop: "25px" }}>
                      Nombre / razón social
                    </div>
                    <div>
                      <strong>{ally?.name || "-"}</strong>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ color: "#767676", marginTop: "25px" }}>
                      NIT
                    </div>
                    <div>
                      <strong>{ally?.nit || "-"}</strong>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div style={{ color: "#767676", marginTop: "25px" }}>
                      Correo electrónico
                    </div>
                    <div>
                      <strong>{ally?.email || "-"}</strong>
                    </div>
                  </Col>

                  <Col span={6}>
                    <div style={{ color: "#767676", marginTop: "25px" }}>
                      Teléfono
                    </div>
                    <div>
                      <strong>{ally?.phoneNumber || "-"}</strong>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        )}
        {loading && (
          <div
            style={{
              width: "100%",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 300,
            }}
          >
            <Spinner />
          </div>
        )}
      </DashboardCard>
      <AllyProducts isProfile />
    </>
  );
}
