import React, { useState, useEffect } from "react";
import { Upload, message, Breadcrumb, Row, Col } from "antd";
import { AiFillPicture } from "react-icons/ai";

import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import {
  DashboardSectionTitle,
  InputLayout,
} from "../../../components/dashboard-layout/styledComponents";
import {
  BreadcrumItem,
  DashboardSaCard,
  Text,
  LabelText,
  ColumnWrap,
  ButtonsContainer,
} from "../Clients/NewClient";
import Button from "../../../components/dashboard-layout/button/Button";
import useInput from "../../../hooks/useInput";

import { Select } from "antd";
import { onCreatePartner } from "../../../Services/firebase";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Spinner from "../../../components/dashboard-SA/Spinner";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const NewAlly = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const allyName = useInput("", isSubmitting);
  const nit = useInput("", isSubmitting, true);
  const phone = useInput("", isSubmitting);
  const email = useInput("", isSubmitting);
  const [billingCycle, setBillingCycle] = useState("ONE_WEEK");
  const [loadingForm, setLoadingForm] = useState(false);
  const history = useHistory();

  const { Option } = Select;

  function handleChangeSelect(value) {
    setBillingCycle(value);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error("Image must smaller than 1MB!");
      return;
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setImage(imageUrl);
      setLoading(false);
    });
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <AiFillPicture size={36} color="#76889A" />

          <div className="ant-upload-text">Subir logo</div>
        </>
      )}
    </div>
  );

  const onCreateAlly = async () => {
    setIsSubmitting(true);
    if(!image) {
      setImageError(true);
      return;
    }

    if (
      !allyName.value ||
      !image ||
      !email.value ||
      !nit.value ||
      !billingCycle ||
      !phone.value
    ) {
      return;
    }

    const form = {
      name: allyName.value,
      email: email.value,
      nit: nit.value,
      phoneNumber: phone.value,
      invoiceCycle: billingCycle,
      imageBase64: image,
    };
    try {
      setLoadingForm(true);
      const res = await onCreatePartner(form);
      console.log(res);
      setLoadingForm(false);
      toast.success("Se ha creado el nuevo aliado satisfactoriamente!");
      history.push("/allies");
    } catch (error) {
      setLoadingForm(false);
      console.log(error);
      toast.error(error?.message || "Ha ocurrido un error inesperado");
    }
  };

  useEffect(()=> {
    if(image) {
      setImageError(false);
    }
  }, [image])

  return (
    <>
      <DashboardSectionTitle>Crear Aliado</DashboardSectionTitle>
      <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
        <BreadcrumItem active href="/allies">
          Aliados
        </BreadcrumItem>
        <BreadcrumItem>Crear nuevo aliado</BreadcrumItem>
      </Breadcrumb>
      <DashboardSaCard height="100%">
        <Row style={{ flexGrow: 1 }}>
          <Col>
            <UploadFile
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {image ? (
                <img src={image} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </UploadFile>
            <Text
              size={12}
              color="#6E8091B3"
              style={{ textAlign: "center", marginBottom: 0 }}
            >
              Tamaño de la imagen: 1 MB
            </Text>
            <ErrorMessage error={imageError ? 1 : 0}>La imagen es requerida</ErrorMessage>
          </Col>
          <Col>
            <VerticalDivider />
          </Col>
          <Col lg={{ span: 8 }}>
            <ColumnWrap>
              <LabelText>Nombre del aliado</LabelText>
              <InputLayout
                type="text"
                readOnly={loadingForm}
                placeholder="Ingresar nombre del aliado"
                size="large"
                value={allyName.value}
                onChange={allyName.onChange}
              />
              <ErrorMessage error={allyName.error}>
                {allyName.error}
              </ErrorMessage>
            </ColumnWrap>
            <ColumnWrap>
              <LabelText>NIT</LabelText>
              <InputLayout
                type="text"
                placeholder="Ingresar NIT"
                size="large"
                readOnly={loadingForm}
                value={nit.value}
                onChange={nit.onChange}
              />
              <ErrorMessage error={nit.error}>{nit.error}</ErrorMessage>
            </ColumnWrap>
            <ColumnWrap style={{ marginBottom: 25 }}>
              <LabelText>Ciclo de Facturación</LabelText>
              <Select
                defaultValue="ONE_DAY"
                value={billingCycle}
                onChange={handleChangeSelect}
                style={{
                  width: "100%",
                  border: "1px solid #76889a81",
                  backgroundColor: "#76889a08",
                  borderRadius: 5,
                }}
              >
                <Option value="ONE_DAY">Un día</Option>
                <Option value="ONE_WEEK">1 Semana</Option>
                <Option value="TWO_WEEK">2 Semanas</Option>
                <Option value="ONE_MONTH">1 Mes</Option>
              </Select>
            </ColumnWrap>
          </Col>
          <Col lg={{ span: 8 }}>
            <ColumnWrap style={{ marginBottom: 25 }}>
              <LabelText>Teléfono</LabelText>
              <InputLayout
                type="text"
                placeholder="Ingresar teléfono"
                size="large"
                maxLength={10}
                readOnly={loadingForm}
                value={phone.value}
                onChange={phone.onChange}
              />
              <ErrorMessage error={phone.error}>{phone.error}</ErrorMessage>
            </ColumnWrap>
            <ColumnWrap>
              <LabelText>Correo Electrónico</LabelText>
              <InputLayout
                type="text"
                placeholder="Ingresar correo electrónico"
                size="large"
                readOnly={loadingForm}
                value={email.value}
                onChange={(e) => {
                  const emailRgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                  if (!emailRgx.test(email.value)) {
                    email.setError("Please use a valid Correo electrónico!");
                  } else {
                    email.setError("");
                  }
                  email.onChange(e);
                }}
              />
              <ErrorMessage error={email.error}>{email.error}</ErrorMessage>
            </ColumnWrap>
          </Col>
        </Row>
        <ButtonsContainer>
          {!loadingForm && <><Button type={"alternative"}>Cancelar</Button>

          <Button type={"primary"} actionButton={onCreateAlly}>
            Crear aliado
          </Button></>}
        </ButtonsContainer>
        {loadingForm && (
          <div
            style={{
              zIndex: 5,
              display: "flex",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              justifyContent: "center"
            }}
          >
            <Spinner />
          </div>
        )}
      </DashboardSaCard>
    </>
  );
};

const UploadFile = styled(Upload)`
  .ant-upload {
    width: 175px;
    height: 146px;
    background: #76889a05 0% 0% no-repeat padding-box;
    border: 1px dashed #76889a80;
  }
`;

export const VerticalDivider = styled.div`
  margin: 0 45px;
  width: 1px;
  background: #76889a29;
  position: relative;
  height: ${(props) => `${props.height}px` || "191px"};
`;

export default NewAlly;
