import React, { useState, useEffect } from "react";
import { Row, Spin } from "antd";
import {
  InputLayout,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import Button from "../../../components/dashboard-layout/button/Button";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";
import { LabelText } from "../../dashboard-sa/Clients/NewClient";
import DropdownSearch from "../../../components/dashboard-SA/DropdownSearch";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { LoadingOutlined } from "@ant-design/icons";
import { createPaymentWhitValueServiceOrder } from "../../../Services/firebase";
import useInput from "../../../hooks/useInput";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);
function RemaningPaymentModal({ modalSetting }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const remainingPayment = useInput("", isSubmitting);
  useEffect(() => {
    if (modalSetting.selectedUser) {
      setSelectedDriver(modalSetting.selectedUser);
    }
    if (modalSetting.serviceOrder) {
      remainingPayment.setValue(modalSetting.serviceOrder.pendingPayment);
    }
    // eslint-disable-next-line
  }, [modalSetting.selectedUser]);
  const handleSelect = (idNumber) => {
    setSelectedDriver(
      modalSetting.users.find((user) => user.idNumber === idNumber)
    );
  };
  const payRemainingAmout = async () => {
    setIsSubmitting(true);
    if (!selectedDriver || !remainingPayment.value) {
      return;
    }
    if (currentStep === 2) {
      try {
        setLoading(true);
        const { data } = await createPaymentWhitValueServiceOrder(
          modalSetting.serviceOrder.id,
          selectedDriver.id,
          +remainingPayment.value
        );
        setLoading(false);
        modalSetting.setServiceOrder({
          ...modalSetting.serviceOrder,
          ...data,
        });
        setCurrentStep(1);
        modalSetting.handleCancel();
        return;
      } catch (error) {
        setLoading(false);
        console.log(error.message);
        if (
          error.message.includes(
            "the amount to pay is not allowed, it is greater than the pending value to pay"
          )
        ) {
          remainingPayment.setError(
            `El valor suministrado supera los $${new Intl.NumberFormat(
              "de-DE"
            ).format(
              +modalSetting.serviceOrder?.pendingPayment
            )} del pago restante`
          );
          setCurrentStep(1);
          return;
        }
      }
    }
    setCurrentStep(2);
  };
  const settings =
    currentStep === 1
      ? { ...modalSetting }
      : {
          ...modalSetting,
          title: (
            <p>
              Confirmar pago restante por valor de{" "}
              <strong style={{ color: "#1645B8" }}>
                ${new Intl.NumberFormat("de-DE").format(remainingPayment.value)}
              </strong>{" "}
              ha{" "}
              <strong style={{ color: "#1645B8" }}>
                {selectedDriver?.User?.firstName} {selectedDriver?.User?.lastName}
              </strong>{" "}
              C.I{" "}
              <strong style={{ color: "#1645B8" }}>
                {selectedDriver?.idNumber}
              </strong>
            </p>
          ),
        };
  return (
    <DashboardModal {...settings} footer={null}>
      {currentStep === 1 && modalSetting.visible ? (
        <>
          <p>
            Para el pago restante puedes enviarlo a otro transportador y el
            monto puede ser igual o menor a{" "}
            <strong>
              $
              {new Intl.NumberFormat("de-DE").format(
                modalSetting.serviceOrder?.pendingPayment
              )}
            </strong>
          </p>
          <Row>
            <DropdownSearch
              onChange={modalSetting.handleSearch}
              onSelect={handleSelect}
              data={modalSetting.users}
              selected={selectedDriver}
              label="Enviar pago restante a"
              removeSelectedUser={() => setSelectedDriver(null)}
            />
            <ErrorMessage error={isSubmitting && !selectedDriver}>
              {isSubmitting &&
                !selectedDriver &&
                "Por favor seleccione un conductor"}
            </ErrorMessage>
          </Row>
          <Row style={{ marginBottom: "50px" }}>
            <LabelText>Monto a enviar</LabelText>
            <InputLayout
              type="text"
              size="large"
              prefix="$"
              value={new Intl.NumberFormat("de-DE").format(
                remainingPayment.value.toString().replace(/[\D\s\._\-]+/g, "")
              )}
              // value={remainingPayment.value}
              onChange={remainingPayment.onFormatCurrency}
            />
            <ErrorMessage error={remainingPayment.error}>
              {remainingPayment.error}
            </ErrorMessage>
          </Row>
        </>
      ) : (
        <p>¿Estas seguro que desea generar el pago restante?</p>
      )}
      <div>
        <FlexContainerHelper justify="flex-end">
          {(currentStep === 1 && (
            <>
              <Button
                type="alternative"
                actionButton={modalSetting.handleCancel}
              >
                Cancel
              </Button>
              <Button type="primary" actionButton={payRemainingAmout}>
                Enviar pago
              </Button>
            </>
          )) || (
            <>
              <Button type="alternative" actionButton={() => setCurrentStep(1)}>
                Cancel
              </Button>
              <Button
                type="primary"
                actionButton={payRemainingAmout}
                disabled={loading}
              >
                {loading ? <Spin indicator={antIcon} /> : "Confirmar"}
              </Button>
            </>
          )}
        </FlexContainerHelper>
      </div>
    </DashboardModal>
  );
}
export default RemaningPaymentModal;