import React, { useState } from "react";
import "./DashboardLayout.css";
import {
  DashboardWrapper,
  DasboardSection,
  DashboardMain,
} from "./styledComponents";
import DashboardNav from "./nav/DashboardNav";
import Header from "./header/Header";

export default function DashboardLayout(props) {
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth < 824;
  const [navState, setNavState] = useState({
    mobile: isMobile,
    show: !isMobile,
  });
  const handleTriggerMenuBtn = () =>
    setNavState({
      ...navState,
      show: !navState.show,
    });
  return (
    <DashboardWrapper>
      <DashboardNav
        responsive={navState.mobile}
        showNav={navState.show}
        handleOpenCloseNav={handleTriggerMenuBtn}
      />
      <DasboardSection>
        <Header handleMenuClick={handleTriggerMenuBtn} history={props.history}/>
        <DashboardMain>{props.children}</DashboardMain>
      </DasboardSection>
    </DashboardWrapper>
  );
}
