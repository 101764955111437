import React, { useState, useEffect } from "react";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns } from "./tableConfig";
import { useParams } from "react-router-dom";
import { getAllyTransactions } from "../../../Services/firebase";
import moment from "moment";

export default function Transactions({ dateFilter }) {
  const params = useParams();
  const [clientTransactions, setClientTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchBarActiveVal, setSearchbarActiveVal] = useState("");
  const onChangeSearchbar = (e) => {
    setSearchbarActiveVal(e.target.value);
  };
  const onGetClientTransactions = async (pag) => {
    setLoading(true);
    try {
      const response = await getAllyTransactions(
        pag.page,
        pag.pageSize,
        dateFilter,
        params.id,
        "PARTNER"
      );
      const responseFormatted = response.data.response.data.map((elm) => ({
        id: elm?.TransactionId || "-",
        manifestNumber: elm?.manifestNumber || "-",
        driverName: `${elm?.origin?.firstName || "-"} ${
          elm?.origin?.lastName || ""
        }`,
        date: moment(elm?.createdAt).format("MMM DD, YYYY  hh:mm a") || "-",
        mount: `$ ${new Intl.NumberFormat("de-DE").format(elm?.value)}`,
        driverPhoto: elm?.urlProfileImage,
        branch: elm?.destination?.name || "-",
        invoiceDiandId: elm?.invoiceDiandId || "-",
        transactionStatus: elm?.TransactionStatus,
      }));
      setClientTransactions(responseFormatted);
      setPagination({
        ...pagination,
        page: response.data.response.pagination.currentPage,
        total: response.data.response.pagination.total,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetClientTransactions(pagination), 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    searchBarActiveVal,
    pagination.page,
    pagination.pageSize,
    dateFilter.startDate,
    dateFilter.endDate,
  ]);

  const onChangePage = (val) => {
    setPagination({ ...pagination, page: val });
  };
  const onChangeItems = (index, val) => {
    setPagination({ ...pagination, pageSize: val });
  };
  return (
    <>
      <DashboardTable
        columns={columns}
        tableTitle={`Total de Transacciones: ${pagination.total}`}
        data={clientTransactions}
        customButton={null}
        pagination={pagination}
        page={pagination.page}
        rowkey="key"
        loading={loading}
        onChangeSearchBar={onChangeSearchbar}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangeItems}
        marginTop={0}
      />
    </>
  );
}
