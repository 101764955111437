import React from "react";
import {
  DashboardSectionTitle,
  InputLayout,
} from "../../../components/dashboard-layout/styledComponents";
import styled from "styled-components";
import { Col, Checkbox, Breadcrumb } from "antd";
import Button from "../../../components/dashboard-layout/button/Button";

const NewClient = () => {
  return (
    <>
      <DashboardSectionTitle>Crear Cliente</DashboardSectionTitle>
      <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
        <BreadcrumItem active href="/clients">
          Clientes
        </BreadcrumItem>
        <BreadcrumItem>Crear nuevo cliente</BreadcrumItem>
      </Breadcrumb>
      <DashboardSaCard>
        <Text>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet.
        </Text>
        <WrapInput>
          <ColumnWrap xs={{ span: 6 }}>
            <LabelText>Razón social</LabelText>
            <InputLayout
              type="text"
              placeholder="Ingresar nombre del aliado"
              size="large"
            />
          </ColumnWrap>
          <ColumnWrap xs={{ span: 6 }}>
            <LabelText>NIT</LabelText>
            <InputLayout type="text" placeholder="Ingresar NIT" size="large" />
          </ColumnWrap>
          <ColumnWrap xs={{ span: 6 }}>
            <LabelText>Teléfono</LabelText>
            <InputLayout
              type="text"
              placeholder="Ingresar teléfono"
              size="large"
            />
          </ColumnWrap>
          <ColumnWrap xs={{ span: 6 }}>
            <LabelText>Correo Electrónico</LabelText>
            <InputLayout
              type="text"
              placeholder="Ingresar correo electrónico"
              size="large"
            />
          </ColumnWrap>
          <ColumnWrap xs={{ span: 6 }}>
            <LabelText>Dirección</LabelText>
            <InputLayout
              type="text"
              placeholder="Ingresar dirección"
              size="large"
            />
          </ColumnWrap>
          <ColumnWrap xs={{ span: 6 }}>
            <LabelText>Cupo</LabelText>
            <InputLayout type="text" placeholder="$0.00" size="large" />
          </ColumnWrap>
        </WrapInput>
        <LabelText>Acuerdo negociado</LabelText>
        <CheckboxContainer>
          <CheckboxInput>Por dia</CheckboxInput>

          <CheckboxInput>Por mes</CheckboxInput>
        </CheckboxContainer>
        <ButtonsContainer>
          <Button type={"alternative"}>Cancelar</Button>

          <Button type={"primary"}>Crear aliado</Button>
        </ButtonsContainer>
      </DashboardSaCard>
    </>
  );
};

export const DashboardSaCard = styled.div`
  width: 100%;
  height: ${(props) => props.height || "559px"};
  border-radius: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  padding: 50px 60px;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: ${(props) => `${props.size}px` || "14px"};
  text-align: left;
  color: ${(props) => props.color || "#585858"};
  margin-bottom: 30px;
`;

export const LabelText = styled.label`
  color: #0a181d;
  font-size: 14px;
  font-weight: 700;
  display: block;
`;

const WrapInput = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ColumnWrap = styled(Col)`
  margin-right: 40px;
  margin-bottom: 32px;
`;

export const BreadcrumItem = styled(Breadcrumb.Item)`
  color: ${(props) => (props.active && `#051A55 !important`) || `#76889A`};
  font-weight: ${(props) => props.active && "bold"};
  font-size: 14px;
`;

const CheckboxInput = styled(Checkbox)`
  margin-right: 100px;
  .ant-checkbox-inner {
    border: 1px solid #76889a;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 14px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const VerticalDivider = styled.div`
  margin: 0 45px;
  width: 1px;
  background: #76889a29;
  position: relative;
  height: ${(props) => `${props.height}px` || "191px"};
`;

export default NewClient;
