import React, { useState, useEffect } from "react";
import {
  DashboardCardTitle,
  FlexContainerHelper,
  DashboardSectionTitle,
} from "../../../components/dashboard-layout/styledComponents";
import {
  WithdrawalDetailLayout,
  BreadcrumItem,
  DashboardSaCard,
  SelectStatus,
  RequestCodeLayout,
} from "./styled-components";
import { Breadcrumb } from "antd";
import { HeaderContainer } from "../Allies/AllyProfile";
import { useParams } from "react-router-dom";
import { Row, Col, Collapse } from "antd";
import moment from "moment";
import {
  getEfectyTransactionDetail,
  updatePaymentStatus,
  refreshEfectyPayment,
} from "../../../Services/firebase";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { toast } from "react-toastify";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Countdown, { zeroPad } from "react-countdown";

const { Panel } = Collapse;

const getStatus = (status) => {
  switch (status) {
    case "PENDING":
      return [
        {
          label: "Pendiente por pago",
          value: "PENDING",
        },
      ];
    case "BLOCKED":
      return [{ label: "Cancelado", value: "BLOCKED" }];
    case "COMPLETED":
      return [{ label: "Pagado", value: "COMPLETED" }];
    default:
      return [{ label: "Pagado", value: "COMPLETED" }];
  }
};

export default function EfectyDetails() {
  const [withdrawal, setWithdrawal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [statusSelected, setStatusSelected] = useState("PENDING");
  const [timer, setTimer] = useState(null);
  const [countdown, setCountDown] = useState("00:00");
  const params = useParams();
  const [loadingPing, setLoadingPin] = useState(false);

  const getWithdrawalRequest = async (requestId) => {
    setLoading(true);
    try {
      const response = await getEfectyTransactionDetail(params.id);
      setWithdrawal(response.data);
      const updateDate = moment(response.data.updatedAt).valueOf();
      setTimer(updateDate);
      setLoading(false);
    } catch (e) {
      console.log(e.response);
      setLoading(false);
    }
  };

  const handleUploadReceipt = async (e) => {
    e.preventDefault();
    if (withdrawal?.status === "COMPLETED" && withdrawal?.urlVoucherImage)
      return;

    setUploadingFile(true);
    try {
      const file = await getBase64File(e.target.files[0]);
      const response = await updatePaymentStatus(params.id, file);
      setWithdrawal({
        ...withdrawal,
        urlVoucherImage: response.data.urlVoucherImage,
        status: response.data.status,
        paidAt: response.data.paidAt,
      });
      toast.success("Pago realizado satisfactoriamente!");
      setUploadingFile(false);
    } catch (e) {
      console.log(e);
      toast.error(
        `Error: ${
          e.message || e.response?.error || "Ha ocurrido un error inesperado"
        }`
      );
      setUploadingFile(false);
    }
  };

  const requestPinRefresh = async () => {
    setLoadingPin(true);
    try {
      const response = await refreshEfectyPayment(parseInt(params.id));
      setLoadingPin(false);
      const updateDate = moment(response.data.updatedAt).valueOf();
      setTimer(updateDate);
      toast.success("Nuevo pin generado exitosamente.");
    } catch (error) {
      setLoadingPin(false);
      toast.error(
        error.message || "ha ocurrido un error inesperado en el servidor."
      );
    }
  };

  const getBase64File = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader(file);
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    getWithdrawalRequest(params.id);
  }, []);

  useEffect(() => {
    const now = moment().valueOf();
    const timeRemains = now - timer;
    setCountDown(timeRemains);
  }, [timer]);

  useEffect(() => {
    if (withdrawal && withdrawal?.status) {
      setStatusSelected(withdrawal?.status || "PENDING");
    }
  }, [withdrawal]);
  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
  return (
    <WithdrawalDetailLayout>
      <HeaderContainer>
        <div>
          <DashboardSectionTitle>Solicitud de retiro</DashboardSectionTitle>
          <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
            <BreadcrumItem active href="/efecty">
              Efecty
            </BreadcrumItem>
            <BreadcrumItem>Solicitud de retiro ID#{params.id}</BreadcrumItem>
          </Breadcrumb>
        </div>
      </HeaderContainer>
      <DashboardSaCard height="651px" style={{ padding: "20px 30px" }}>
        {!loading && (
          <>
            <DashboardCardTitle>
              Solicitud de retiro ID#{params.id}
            </DashboardCardTitle>
            <Row align="middle">
              <Col span={7}>
                <FlexContainerHelper justify="space-between">
                  <span>
                    <strong>Transportador:</strong>
                  </span>
                  <span>
                    {`${withdrawal?.User?.firstName} ${withdrawal?.User?.lastName}` ||
                      "-"}
                  </span>
                </FlexContainerHelper>
                <FlexContainerHelper justify="space-between">
                  <span>
                    <strong>Organización:</strong>
                  </span>
                  <span>
                    {withdrawal?.User?.Driver?.Organizations[0]?.name || "-"}
                  </span>
                </FlexContainerHelper>
              </Col>
              <Col span={6} offset={1}>
                <FlexContainerHelper justify="space-between">
                  <span>
                    <strong>Fecha de Solicitud:</strong>
                  </span>
                  <span>
                    {moment(withdrawal?.createdAt).format(
                      "MMM DD, YYYY  hh:mm a"
                    ) || "-"}
                  </span>
                </FlexContainerHelper>
                {statusSelected === "COMPLETED" && (
                  <FlexContainerHelper justify="space-between">
                    <span>
                      <strong>Fecha de pago:</strong>
                    </span>
                    <span>
                      {withdrawal?.paidAt
                        ? moment(withdrawal?.paidAt).format(
                            "MMM DD, YYYY  hh:mm a"
                          )
                        : "-"}
                    </span>
                  </FlexContainerHelper>
                )}
                {statusSelected === "BLOCKED" && (
                  <FlexContainerHelper justify="space-between">
                    <span>
                      <strong>Fecha de pago:</strong>
                    </span>
                    <span>
                      {withdrawal?.paidAt
                        ? moment(withdrawal?.paidAt).format(
                            "MMM DD, YYYY  hh:mm a"
                          )
                        : "-"}
                    </span>
                  </FlexContainerHelper>
                )}
              </Col>
              <Col span={4} offset={6}>
                <FlexContainerHelper
                  direction="column"
                  align="flex-end"
                  className="amount-status-container"
                >
                  <span>Valor a retirar</span>
                  <DashboardSectionTitle className="withdrawal-amount">
                    {`$ ${new Intl.NumberFormat("de-DE").format(
                      withdrawal?.value
                    )}`}
                  </DashboardSectionTitle>
                  <div>
                    <SelectStatus
                      currentStatus={statusSelected}
                      onChange={(value) => setStatusSelected(value)}
                      options={getStatus(withdrawal?.status)}
                      defaultValue={statusSelected}
                      dropdownClassName="payment-status"
                      hideArrow
                      inputProps={{
                        readOnly:
                          statusSelected === "COMPLETED" &&
                          !!withdrawal?.urlVoucherImage,
                      }}
                    />
                  </div>
                </FlexContainerHelper>
              </Col>
            </Row>
            {statusSelected === "PENDING" && (
              <Row>
                <Col span={24}>
                  <RequestCodeLayout>
                    <h4>Detalle de la transacción</h4>
                    {!uploadingFile ? (
                      <div className="upload-receipt-component">
                        <AiOutlineInfoCircle size={34} />
                        <p>
                          Este transportador tiene un código de retiro activo
                        </p>
                        <span>
                          Tiempo restante:{" "}
                          <Countdown
                            date={Date.now() + (3600000 - countdown)}
                            renderer={renderer}
                          />
                        </span>
                        {!loadingPing ? (
                          <button onClick={() => requestPinRefresh()}>
                            Generar nuevo código
                          </button>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 200,
                            }}
                          >
                            <Spinner />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 200,
                        }}
                      >
                        <Spinner />
                      </div>
                    )}
                  </RequestCodeLayout>
                </Col>
              </Row>
            )}
            {statusSelected === "COMPLETED" && (
              <Row className="file-container" align="middle">
                <Col span={24}>
                  <h4>Detalle de la transacción</h4>
                </Col>
                <Col span={12}>
                  <span className="file-name">
                    <strong>Código de seguimiento Efecty: </strong>
                    {withdrawal.trackingCode}
                  </span>
                </Col>
              </Row>
            )}
            {statusSelected === "BLOCKED" && (
              <Row className="file-container" align="middle">
                <Col span={24}>
                  <h4>Detalle de la transacción</h4>
                </Col>
                <Col span={12}>
                  <span className="file-name">
                    <strong>Tipo de cancelación:</strong>
                  </span>
                </Col>
              </Row>
            )}
          </>
        )}
        {loading && (
          <div
            style={{
              width: "100%",
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <Spinner />
          </div>
        )}
      </DashboardSaCard>
    </WithdrawalDetailLayout>
  );
}
