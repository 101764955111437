import React, { useEffect, useState } from "react";
import { useSelectableRow } from "../../dashboard/transporters/tableConfig";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import usePagination from "../../../hooks/usePagination";
import moment from "moment";
import { DatePicker, Row, Col } from "antd";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { BsFillPeopleFill } from "react-icons/bs";
import {
  getCommissionTotal,
  getInvoicesFromSuperAdmin,
} from "../../../Services/firebase";
import { columns } from "./tableConfig";
import { FaTruck, FaShoppingBag } from "react-icons/fa";

const { RangePicker } = DatePicker;

const Comissions = ({ history }) => {
  const rowSelectable = useSelectableRow();
  const [comissions, setComissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAllyComission, setloadingAllyComission] = useState(false);
  const [loadingDriverComission, setloadingDriverComission] = useState(false);
  const [driverComission, setDriverComission] = useState(null);
  const [allyComission, setAllyComission] = useState(null);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const getTotalComissionValue = async (
    type,
    dates,
    activateLoading,
    setValue
  ) => {
    activateLoading(true);
    try {
      const response = await getCommissionTotal(dates, type);
      setValue(response.data);
      activateLoading(false);
    } catch (e) {
      console.log(e);
      activateLoading(false);
    }
  };
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();

  const onGetComissionList = async () => {
    try {
      setLoading(true);

      const { data } = await getInvoicesFromSuperAdmin(
        page,
        itemsPerPage,
        dateRange,
        undefined,
        "ORGANIZATION"
      );
      const formatedRows = data?.data?.map((row) => ({
        id: row?.id || "-",
        comissionType: row?.destinationType || "-",
        name: row?.destination?.name || "-",
        comissionProportion: row?.value || "-",
        comissionValue: row?.value || "-",
      }));
      setPagination(data?.pagination);
      setComissions(formatedRows);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onGetComissionList();
  }, [page, itemsPerPage, dateRange]);

  useEffect(() => {
    getTotalComissionValue(
      "PARTNER",
      dateRange,
      setloadingAllyComission,
      setAllyComission
    );
  }, [dateRange]);

  useEffect(() => {
    getTotalComissionValue(
      "DRIVER",
      dateRange,
      setloadingDriverComission,
      setDriverComission
    );
  }, [dateRange]);

  const onClickRow = (e, row) => {
    if (
      e.target.parentElement.className.includes("icons") ||
      e.target.tagName === "svg"
    ) {
      return;
    }
    history.push(`/invoice/${row.id}?Org`);
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <DashboardSectionTitle>Indicadores</DashboardSectionTitle>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
          <Row gutter={8} justify="end">
            <Col span={12}>
              <DashboardItem
                title="Comisiones de Aliados"
                value={`$ ${new Intl.NumberFormat("de-DE").format(
                  allyComission
                )}`}
                factor=""
                icon={<FaShoppingBag />}
                iconColor="#00BE6D"
                iconBg="#E5F9F0"
                factorStatus="down"
                loading={loadingAllyComission}
              />
            </Col>
            <Col span={12}>
              <DashboardItem
                title="Comisiones de Transportadores"
                value={`$ ${new Intl.NumberFormat("de-DE").format(
                  driverComission
                )}`}
                factor=""
                icon={<FaTruck />}
                iconColor="#FFA81D"
                iconBg="#FFA81D0D"
                factorStatus="down"
                loading={loadingDriverComission}
                link={{
                  url: "/comisiones/drivers",
                  color: "#FFA81D",
                  text: "Ver detalle",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <DashboardTable
        columns={columns}
        pagination={pagination}
        rowSelectable={rowSelectable}
        tableTitle="Lista total de comisiones"
        data={comissions}
        page={page}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        loading={loading}
        onClickRow={onClickRow}
      />
    </>
  );
};

export default Comissions;
