import React from 'react';
import { FlexContainerHelper, Text } from '../../../../components/dashboard-layout/styledComponents';
import moment from 'moment';
import Box from '../../../../components/Box';
import {StatusItem} from '../../../../components/dashboard-SA/InvoicesItem';
import { Divider } from 'antd';

const getTagColor = (status) => {
  if(status === 'CREATED') {
    return 'error';
  }

  if (status === 'PAYMENT') {
    return 'warning'
  }

  if(status === 'COMPLETED') {
    return 'success'
  }
}
const getTagText = (status) => {

  if(status === 'CREATED') {
    return 'Creada';
  }

  if (status === 'PAYMENT') {
    return 'Anticipo generado'
  }

  if(status === 'COMPLETED') {
    return 'Finalizada'
  }
}


const OrderDriver = ({id, address, manifestNumber, createdAt, status}) => (
  <>

  <FlexContainerHelper justify="space-between">
    <div>
      <h5 style={{margin: 0}}>Orden de servicio #{id}</h5>
      <span >{manifestNumber}</span>
      <span>{address}</span>
    </div>
    <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="space-between" marginTop={0}>
      <StatusItem  
        status={getTagColor(status)} 
        style={{width: 'fit-content', marginRight: 0, fontSize: 12, padding: '3.6px 12px', height: 27.2}}>       
          {getTagText(status)}    
      </StatusItem>
      <Text size={12} color="#6E8091B3" style={{ margin: 0, fontWeight: 500 }}>
          {moment(createdAt).format('MMMM DD, hh:mm a')}
      </Text>

    </Box>
  </FlexContainerHelper>
  <Divider />
  </>
);



export default OrderDriver;