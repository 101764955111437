import React, { useState, useEffect } from "react";
import {
  DashboardSectionTitle,
  DashboardCard,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import { Row, Col } from "antd";
import Button from "../../../components/dashboard-layout/button/Button";
import Logo from "../../../components/auth-layout/logo/logo";
import { FiLogOut } from "react-icons/fi";
import { useContext } from "react";
import { Context } from "../../../Context";
import { getOrganization } from '../../../Services/firebase';
import clientLogo from "../../../assets/img/profileOrga.png";

export default function UserForm() {
  const { removeAuth } = useContext(Context);
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    const onGetOrganization = async () => {
      try {
        const {data} = await getOrganization();
        setProfile(data);
      } catch (error) {
        console.log(error);
      }
    }
    onGetOrganization();
  }, []);

  return (
    <>
      <FlexContainerHelper justify="space-between">
        <DashboardSectionTitle>Perfil</DashboardSectionTitle>
      </FlexContainerHelper>
      <DashboardCard direction={"column"}>
        <Row>
          <Col
            span={6}
            style={{
              borderRight: "solid 1px #e3e3e3",
              minHeight: "45vh",
              overflow: "hidden",
            }}
          >
            <Logo imageSource={profile?.imageUrl || clientLogo} altText={"Express"} />
            <Button type="red_express" actionButton={removeAuth}>
              <FiLogOut size={18} /> Cerrar sesión
            </Button>
          </Col>
          <Col
            span={18}
            style={{
              minHeight: "45vh",
              overflow: "hidden",
              paddingLeft: "30px",
            }}
          >
            <div>
              <Row>
                <Col span={8}>
                  <div style={{ color: "#767676", marginTop: "25px" }}>
                    Nombre / razón social
                  </div>
                  <div>
                    <strong>{profile?.name}</strong>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ color: "#767676", marginTop: "25px" }}>NIT</div>
                  <div>
                    <strong>{profile?.nit}</strong>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ color: "#767676", marginTop: "25px" }}>
                  Correo electrónico
                  </div>
                  <div>
                    <strong>{profile?.email}</strong>
                  </div>
                </Col>
                {/* <Col span={8}>
                  <div style={{ color: "#767676", marginTop: "25px" }}>
                    Dirección
                  </div>
                  <div>
                    <strong>Cl. 46a #85 A – 51, Bogotá Colombia.</strong>
                  </div>
                </Col> */}
                <Col span={8}>
                  <div style={{ color: "#767676", marginTop: "25px" }}>
                    Teléfono
                  </div>
                  <div>
                    <strong>{profile?.phoneNumber}</strong>
                  </div>
                </Col>
                <Col span={24}>
                  <div style={{ color: "#767676", marginTop: "25px" }}>
                    <strong>Description</strong>
                  </div>
                  <div>
                    <p>
                    {profile?.description}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </DashboardCard>
    </>
  );
}
