import React, { useState, useEffect } from "react";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns } from "./transactionTable";
import { useParams } from "react-router-dom";
import { getAllyTransactions } from "../../../Services/firebase";
import moment from "moment";

export default function ClientTransactions({ dateRange }) {
  const params = useParams();
  const [clientTransactions, setClientTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchBarActiveVal, setSearchbarActiveVal] = useState("");
  const onChangeSearchbar = (e) => {
    setSearchbarActiveVal(e.target.value);
  };
  const onGetClientTransactions = async (pag) => {
    setLoading(true);
    try {
      const response = await getAllyTransactions(
        pag.page,
        pag.pageSize,
        { startDate: dateRange[0], endDate: dateRange[1] },
        params.id,
        "ORGANIZATION"
      );
      const responseFormatted = response.data.response.data.map((elm) => ({
        id: elm?.TransactionId || "-",
        manifestNumber: elm?.manifestNumber || "-",
        driverName:
          `${elm?.destination?.firstName} ${elm.destination.lastName}` || "-",
        date: moment(elm?.createdAt).format("MMM DD, YYYY  hh:mm a") || "-",
        mount: `$ ${new Intl.NumberFormat("de-DE").format(elm?.value * -1)}`,
        driverPhoto: elm?.urlProfileImage,
      }));
      setClientTransactions(responseFormatted);
      setPagination({
        ...pagination,
        page: response.data.response.pagination.currentPage,
        total: response.data.response.pagination.total,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => onGetClientTransactions(pagination), 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchBarActiveVal, pagination.page, pagination.pageSize, dateRange]);
  const onChangePage = (val) => {
    setPagination({ ...pagination, page: val });
  };
  return (
    <>
      <DashboardTable
        columns={columns}
        tableTitle={`Total de Transacciones: ${pagination.total}`}
        data={clientTransactions}
        customButton={null}
        pagination={pagination}
        page={pagination.page}
        rowkey="key"
        loading={loading}
        onChangeSearchBar={onChangeSearchbar}
        onChangePage={onChangePage}
      />
    </>
  );
}
