import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getInvoicesFromSuperAdmin,
  getOrganization,
  onGetServiceOrders,
} from "../../../Services/firebase";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { Breadcrumb } from "antd";
import {
  BreadcrumItem,
  DashboardSaCard,
  ButtonsContainer,
  VerticalDivider,
} from "./NewClient";
import styled from "styled-components";
import Button from "../../../components/dashboard-layout/button/Button";
import { IoMdTrash } from "react-icons/io";
import { MdBlock } from "react-icons/md";
import ProfileInvoices from "../../../components/dashboard-SA/ProfileInvoices";
import { Tabs, DatePicker, Row, Col } from "antd";
import Box from "../../../components/Box";
import ClientInfo from "../../../components/dashboard-SA/ClientInfo";
import ProfileServiceOrder from "../../../components/dashboard-SA/ProfileServiceOrders";
import { useInfinityScroll } from "../../../hooks/useInfinityScroll";
import ClientTransactions from "./ClientTransactions";
import moment from "moment";

const trashIcon = () => <IoMdTrash size={12} color="#FFFFFF" />;
const blockIcon = () => <MdBlock size={12} color="#00BE6D" />;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

export default function ClientProfile() {
  const [client, setClient] = useState(null);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientInvoices, setClientInvoices] = useState([]);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const [currentTab, setCurrentTab] = useState(1);
  const [soPagination, setSoPagination] = useState({
    page: 1,
    itemsPerPage: 10,
    total: 0,
  });
  //const [error, setError] = useState(null);
  const params = useParams();
  const scrollHook = useInfinityScroll(
    serviceOrders,
    setServiceOrders,
    onGetServiceOrders,
    soPagination,
    setSoPagination,
    params.id
  );
  const onGetClient = async (clientId) => {
    setLoading(true);
    try {
      const response = await getOrganization(parseInt(clientId));
      const serviceOrdersData = await onGetServiceOrders(
        soPagination.page,
        soPagination.itemsPerPage,
        dateRange,
        null,
        params.id
      );
      setClient(response.data);
      setServiceOrders([...serviceOrders, ...serviceOrdersData.data.data]);
      setSoPagination({
        ...soPagination,
        page: serviceOrdersData.data.pagination.currentPage,
        itemsPerPage: serviceOrdersData.data.pagination.itemsPerPage,
        total: serviceOrdersData.data.pagination.totalPages,
      });
      setLoading(false);
    } catch (e) {
      console.log(e.response);
      setLoading(false);
    }
  };
  const getServiceOrders = async (clientId) => {
    try {
      const serviceOrdersData = await onGetServiceOrders(
        soPagination.page,
        soPagination.itemsPerPage,
        dateRange,
        null,
        params.id
      );
      setServiceOrders([...serviceOrders, ...serviceOrdersData.data.data]);
      setSoPagination({
        ...soPagination,
        page: serviceOrdersData.data.pagination.currentPage,
        itemsPerPage: serviceOrdersData.data.pagination.itemsPerPage,
        total: serviceOrdersData.data.pagination.totalPages,
      });
    } catch (e) {
      console.log(e.response);
    }
  };
  useEffect(() => {
    if (!client) {
      onGetClient(params.id);
    }
  }, [client]);

  const onGetClientInvoices = async () => {
    try {
      const { data } = await getInvoicesFromSuperAdmin(
        1,
        30,
        dateRange,
        params.id,
        "ORGANIZATION"
      );
      const invoices = data.data.map((invoice, i) => {
        if (invoice.status === "PAID") {
          return { ...invoice, statusColor: "success", statusName: "Pagado" };
        }

        if (invoice.status === "PENDING_CONFIRM_PAY") {
          return {
            ...invoice,
            statusColor: "error",
            statusName: "Pendiente por confirmar pago",
          };
        }
        if (invoice.status === "PENDING_PAY") {
          return {
            ...invoice,
            statusColor: "warning",
            statusName: "Pendiente por pago",
          };
        }
      });
      setClientInvoices(invoices);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onGetClientInvoices();
    getServiceOrders();
  }, [params.id, dateRange]);

  return (
    <>
      <Row align="middle">
        <Col span={12}>
          <div>
            <DashboardSectionTitle>
              Perfil del Cliente ID#{params.id || ""}
            </DashboardSectionTitle>
            <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
              <BreadcrumItem active href="/clients">
                Clientes
              </BreadcrumItem>
              <BreadcrumItem>Perfil del cliente</BreadcrumItem>
            </Breadcrumb>
          </div>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
        </Col>
      </Row>

      <DashboardSaCard height="651px" style={{ padding: "20px 30px" }}>
        <Tabss
          defaultActiveKey="1"
          onChange={(activeKey) => setCurrentTab(parseInt(activeKey))}
        >
          <TabPane tab="Perfil" key="1">
            <Box display="flex">
              <Box flex="1">
                <ClientInfo allyProfile={client} loadingProfile={loading} />
              </Box>
              <Box>
                <VerticalDivider height={500} style={{ margin: "0 20px" }} />
              </Box>
              <Box flex="1">
                <ProfileServiceOrder
                  title="Ordenes de Servicio"
                  loading={loading}
                  items={serviceOrders}
                  containerRef={scrollHook.containerRef}
                  scrollLoading={scrollHook.loadingScroll}
                  handleScroll={scrollHook.handleScrolling}
                />
              </Box>
              <Box>
                <VerticalDivider height={500} style={{ margin: "0 20px" }} />
              </Box>
              <Box flex="1">
                <ProfileInvoices
                  items={clientInvoices}
                  title={"Historial de facturas"}
                  loadingInvoices={loading}
                  linkTo
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <ButtonsContainer>
                <Button type={"success"} icon={trashIcon()}>
                  Eliminar perfil
                </Button>
                <Button icon={blockIcon()} type={"success_secondary"}>
                  Bloquear perfil
                </Button>
              </ButtonsContainer>
            </Box>
          </TabPane>
          <TabPane tab="Transacciones" key="2">
            <ClientTransactions dateRange={dateRange} />
          </TabPane>
        </Tabss>
      </DashboardSaCard>

      {/*  <ProductForm modalSetting={modal} /> */}
    </>
  );
}

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Tabss = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-weight: 500;
    min-width: 100px !important;
    text-align: center !important;
  }
`;
