import React, { useContext, useEffect, useState } from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { Context } from "../../../Context";
import { getBranchsTrasactions } from "../../../Services/firebase";
import moment from "moment";
import styled from "styled-components";
import { DatePicker, Row, Col } from "antd";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";

const { RangePicker } = DatePicker;

const columns1 = [
  {
    title: "Sucursal",
    dataIndex: "sucursal",
  },
  {
    title: "Total de transacciones",
    dataIndex: "totalTrasactions",
  },
];
const columns2 = [
  {
    title: "Sucursal",
    dataIndex: "sucursal",
  },
  {
    title: "Monto",
    dataIndex: "amount",
  },
];
const columns3 = [
  {
    title: "Transportador",
    dataIndex: "driver",
    key: "driver",
    render: (text) => <span>{text}</span>,

    sortDirections: ["descend"],
  },

  {
    title: "Sucursal",
    dataIndex: "branch",
    key: "branch",

    sortDirections: ["descend"],
  },
  {
    title: "Producto",
    dataIndex: "product",
    key: "product",

    sortDirections: ["descend"],
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",
    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "amount",
    key: "amount",
    sortDirections: ["descend"],
  },
];

const DashboardAlly = () => {
  const { currentUser } = useContext(Context);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const onChangePage = (val) => {
    setPagination({ ...pagination, page: val });
  };

  useEffect(() => {
    onGetBranchsTrasactions(pagination);
  }, [pagination.page, dateRange]);

  const onGetBranchsTrasactions = async (pag) => {
    setLoading(true);
    try {
      const { data } = await getBranchsTrasactions(
        pag.page,
        pag.pageSize,
        dateRange
      );
      const formatedRows = data?.response?.data?.map((row) => ({
        driver: `${row?.origin?.firstName} ${row?.origin?.lastName}`,
        branch: row?.destination?.name,
        product: row?.productName,
        date: moment(row?.createdAt).format("MMM DD, YYYY  hh:mm a"),
        amount: `$ ${new Intl.NumberFormat("de-DE").format(row?.value)}`,
      }));
      setTransactions(formatedRows);
      setPagination({
        ...pag,
        page: data?.response?.pagination.currentPage,
        total: data?.response?.pagination.total,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <HomeAllyLayout>
      <Row align="middle">
        <Col span={12}>
          <DashboardSectionTitle>Dashboard</DashboardSectionTitle>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
        </Col>
      </Row>
      <DashboardTable
        tableTitle="Últimas transacciones"
        className="larga-table-ally"
        columns={columns3}
        data={transactions}
        loading={loading}
        pagination={pagination}
        onChangePage={onChangePage}
        page={pagination.page}
        searchbarPlaceholder="Buscar transacción"
      />
      {/* <Box display="flex" marginBottom={25}>
        <MiniTable
          title="Sucursales con más transacciones"
          columnsData={columns1}
          rows={data1}
          maxWidth={900}
        />
        <MiniTable
          title="Sucursales con mayor facturación"
          columnsData={columns2}
          rows={data2}
          maxWidth={540}
          marginRight={0}
        />
      </Box> */}
    </HomeAllyLayout>
  );
};

const HomeAllyLayout = styled.div`
  & .large-table-ally {
    height: auto;
  }
`;

export default DashboardAlly;
