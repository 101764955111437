import React, { useCallback, useEffect, useState } from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { Breadcrumb, Row, Table, Upload, message, Pagination } from "antd";
import {
  BreadcrumItem,
  ButtonsContainer,
  Text,
} from "../../dashboard-sa/Clients/NewClient";
import Button from "../../../components/dashboard-layout/button/Button";
import { IoIosAddCircleOutline } from "react-icons/io";
import styled from "styled-components";
import { Select } from "antd";
import {
  getInvoiceDetails,
  getOrganization,
  updateStatusInvoice,
} from "../../../Services/firebase";
import moment from "moment";
import Box from "../../../components/Box";
import { AiFillFile } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { HeaderContainer } from "../../dashboard-sa/Allies/AllyProfile";
import Spinner from "../../../components/dashboard-SA/Spinner";

const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const renderClientInvoices = () => {
  const columns = [
    {
      title: "ID Solicitud de Servicio",
      dataIndex: "id",
      key: "id",
      sortDirections: ["descend"],
    },
    {
      title: "Nº Manifiesto",
      dataIndex: "nManifest",
      key: "nManifest",

      sortDirections: ["descend"],
    },

    {
      title: "Transportador",
      dataIndex: "driver",
      key: "driver",

      sortDirections: ["descend"],
    },
    {
      title: "Cedula",
      dataIndex: "idNumber",
      key: "idNumber",

      sortDirections: ["descend"],
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",

      sortDirections: ["descend"],
    },

    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
      sortDirections: ["descend"],
    },
  ];

  return { columns };
};

const renderAllyInvoices = () => {
  const columns = [
    {
      title: "ID Solicitud de Servicio",
      dataIndex: "id",
      key: "id",
      sortDirections: ["descend"],
    },

    {
      title: "Sucursal",
      dataIndex: "branch",
      key: "id",

      sortDirections: ["descend"],
    },
    {
      title: "Transportador",
      dataIndex: "driver",
      key: "driver",

      sortDirections: ["descend"],
    },
    {
      title: "Cedula",
      dataIndex: "idNumber",
      key: "idNumber",

      sortDirections: ["descend"],
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",

      sortDirections: ["descend"],
    },
    {
      title: "Producto",
      dataIndex: "product",
      key: "product",
      sortDirections: ["descend"],
    },
    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
      sortDirections: ["descend"],
    },
  ];

  return { columns };
};

const getSelectBg = (status) => {
  if (status === "PENDING_CONFIRM_PAY") {
    return "#E4032B";
  }

  if (status === "PENDING_PAY") {
    return "#F84B05";
  }

  if (status === "PAID") {
    return "#00BE6D";
  }
};

const InvoicesDetailsSA = ({ match }) => {
  const dataTable = renderClientInvoices();
  const allyTable = renderAllyInvoices();
  const [status, setStatus] = useState("");
  const [extension, setExtension] = useState("");
  const [invoiceDetails, setInvoicesDetails] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);

  const [updateStatusError, setUpdateStatusError] = useState("");

  const [pagination, setPagination] = useState({
    page: 1,
    itemsPerPage: 10,
    total: 0,
  });

  const onChangePage = (page) => {
    setPagination({ ...pagination, page: page });
  };
  const onChangeItemsPerPage = (index, itemsLength) => {
    setPagination({ ...pagination, itemsPerPage: itemsLength });
  };

  useEffect(() => {
    const onGetInvoiceDetails = async () => {
      try {
        setLoadingData(true);
        const { page, itemsPerPage } = pagination;
        const { data } = await getInvoiceDetails(
          page,
          itemsPerPage,
          match.params.id
        );
        const tableData = data?.detail?.map((row) => {
          if (row?.destinationType.includes("PARTNER")) {
            return {
              id: row.id,
              nManifest: row.manifestNumber,
              driver: `${row?.origin?.User?.firstName} ${row?.origin?.User?.lastName}`,
              idNumber: row?.origin?.idNumber,
              date: moment(row.createdAt).format("MMMM DD, YYYY"),
              amount: `$ ${new Intl.NumberFormat("de-DE").format(row.value)}`,
              product: row?.productName || "-",
              branch: row?.destination?.name || "-",
            };
          }

          return {
            id: row.id,
            nManifest: row.manifestNumber,
            driver: `${row?.destination?.User?.firstName} ${row?.destination?.User?.lastName}`,
            idNumber: row?.destination?.idNumber,
            date: moment(row.createdAt).format("MMMM DD, YYYY"),
            amount: `$ ${new Intl.NumberFormat("de-DE").format(row.value)}`,
          };
        });
        setPagination({
          ...pagination,
          page: data?.pagination?.currentPage,
          total: data?.pagination?.total,
        });
        setStatus(data?.invoice?.status);
        setInvoicesDetails({ data: tableData, details: data.invoice });
        if (data.invoice?.urlProfileImage) {
          setImage(data.invoice?.urlProfileImage);
        }
        setLoadingData(false);
      } catch (error) {
        console.log(error);
        setLoadingData(false);
      }
    };
    onGetInvoiceDetails();
  }, [pagination.itemsPerPage, match.params.id, pagination.page]);

  async function handleUpdateStatus(value) {
    if (
      window.location.search.includes("Org") &&
      status === "PENDING_CONFIRM_PAY"
    ) {
      try {
        setLoadingUpdateStatus(true);
        await updateStatusInvoice(match.params.id, null, value);
        setStatus(value);
        setLoadingUpdateStatus(false);
      } catch (error) {
        if (error.message === "Invoice Status is not correct") {
          setUpdateStatusError(
            "Se require que la Organization adjunte una factura."
          );
        }
        setLoadingUpdateStatus(false);
        console.log(error.message);
        return;
      }
    }
    setStatus(value);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt2M = file.size / 2048 / 2048 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return;
    }
    setExtension(file.type.split("/")[1]);
    return isJpgOrPng && isLt2M;
  }

  const handleChange = useCallback(
    (info) => {
      const handleChangeUpdateImg = (info) => {
        getBase64(info.file.originFileObj, async (imageUrl) => {
          try {
            setLoading(true);
            const { data } = await updateStatusInvoice(
              match.params.id,
              imageUrl,
              status
            );
            setImage(data.urlProfileImage);
            setLoading(false);
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
        });
      };
      handleChangeUpdateImg(info);
    },
    [match.params.id, status]
  );

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <Text
            size={12}
            color="#607384"
            style={{ textAlign: "center", marginBottom: 0 }}
          >
            Arrastre y suelte su archivo aquí o presione el botón para cargar
          </Text>
          {/* <AiFillPicture size={36} color="#76889A" /> */}
          <Box
            display="flex"
            justifyContent="center"
            marginTop="10px"
            marginBottom="10px"
          >
            <Button
              type="success"
              disabled={loading}
              icon={<IoIosAddCircleOutline color="#fff" size="16" />}
            >
              Adjuntar archivo
            </Button>
          </Box>
          <Text
            size={12}
            color="#6E8091B3"
            style={{ textAlign: "center", marginBottom: 0 }}
          >
            Solo .png .jpg . Pdf con un tamaño max del archivo de 2MB
          </Text>
        </>
      )}
    </div>
  );

  return (
    <>
      <HeaderContainer>
        <div>
          <DashboardSectionTitle>Detalle de la factura</DashboardSectionTitle>
          <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
            <BreadcrumItem active href="/billings">
              Facturación
            </BreadcrumItem>
            <BreadcrumItem>Valor a pagar a HOY+</BreadcrumItem>
          </Breadcrumb>
        </div>
        <ButtonsContainer>
          {/* <Button
            type={"success"}
            icon={<IoMdDownload size={16} color="#FFFFFF" />}
          >
            Descargar en PDF
          </Button> */}
          {/* <Button
            icon={<IoMdPrint size={16} color="#00BE6D" />}
            type={"success_secondary"}
          >
            Imprimir
          </Button> */}
        </ButtonsContainer>
      </HeaderContainer>
      {loadingData ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop="50px"
          flexDirection="column"
        >
          <Spinner />
          <Text color="#76889A" size={16} style={{ marginTop: 20 }}>
            Cargando detalle de factura
          </Text>
        </Box>
      ) : (
        <BillingCard style={{ maxHeight: 800, height: "unset" }}>
          <BillingCardHeader>
            <Row
              justify="space-between"
              style={{ alignItems: "center", marginBottom: 4 }}
            >
              <Text
                color="#051A55"
                size={14}
                style={{ fontWeight: 600, marginBottom: 0 }}
              >
                ID #{invoiceDetails?.details?.id}
              </Text>
              <Text
                color="#76889A"
                size={12}
                style={{ fontWeight: "bold", marginBottom: 0 }}
              >
                Monto Adeudado
              </Text>
            </Row>
            <Row justify="space-between" style={{ alignItems: "center" }}>
              <Text
                color="#0A181D"
                size={24}
                style={{ marginBottom: 0, fontWeight: "bold" }}
              >
                {invoiceDetails?.details?.destination?.name || ""}
              </Text>
              <Text
                color="#0A181D"
                size={24}
                style={{ marginBottom: 0, fontWeight: "bold" }}
              >
                ${" "}
                {new Intl.NumberFormat("de-DE").format(
                  invoiceDetails?.details?.value || 0
                )}
              </Text>
            </Row>
            <Row justify="space-between" style={{ marginTop: 5 }}>
              <Text color="#76889A" size={12}>
                {`${moment(invoiceDetails?.details?.initDate).format(
                  "MMMM DD, hh:mm a"
                )} - ${moment(invoiceDetails?.details?.endDate).format(
                  "MMMM DD, hh:mm a"
                )}`}
              </Text>
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <SelectComp
                  value={status}
                  status={status}
                  onChange={handleUpdateStatus}
                  loadingUpdateStatus={loadingUpdateStatus}
                  disabled={
                    (status === "PENDING_PAY" &&
                      window.location.search.includes("Org")) ||
                    (status === "PENDING_CONFIRM_PAY" &&
                      !window.location.search.includes("Org"))
                  }
                >
                  {status === "PENDING_PAY" &&
                    !window.location.search.includes("Org") && (
                      <>
                        <Option value="PENDING_CONFIRM_PAY">
                          Pendiente Por verificar
                        </Option>
                        <Option value="PENDING_PAY">Pendiente por Pago</Option>
                      </>
                    )}
                  {status === "PENDING_PAY" &&
                    window.location.search.includes("Org") && (
                      <>
                        <Option value="PENDING_PAY">Pendiente por Pago</Option>
                      </>
                    )}
                  {status === "PENDING_CONFIRM_PAY" &&
                    window.location.search.includes("Org") && (
                      <>
                        <Option value="PENDING_CONFIRM_PAY">
                          Pendiente Por verificar
                        </Option>
                        <Option value="PAID">Pagado</Option>
                      </>
                    )}
                  {status === "PAID" && window.location.search.includes("Org") && (
                    <>
                      <Option value="PAID">Pagado</Option>
                    </>
                  )}
                  {status === "PENDING_CONFIRM_PAY" &&
                    !window.location.search.includes("Org") && (
                      <>
                        <Option value="PENDING_CONFIRM_PAY">
                          Pendiente Por verificar
                        </Option>
                      </>
                    )}
                </SelectComp>
                <div style={{ color: "red", fontSize: 12, marginTop: 7 }}>
                  {updateStatusError}
                </div>
              </Box>
            </Row>
          </BillingCardHeader>
          {status === "PENDING_CONFIRM_PAY" && (
            <>
              {!image ? (
                <>
                  <DivideSection>
                    <Text
                      color="#0A181D"
                      size={14}
                      style={{ marginBottom: 0, fontWeight: "500" }}
                    >
                      Comprobante de pago
                    </Text>
                  </DivideSection>
                  <Box padding="0 50px">
                    <UploadFile
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      {uploadButton}
                    </UploadFile>
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="0 50px"
                  marginBottom="40px"
                >
                  <Box display="flex" alignItems="center">
                    <AiFillFile color="#1CA892" size="15px" />
                    <Text style={{ marginBottom: 0, marginLeft: 10 }}>
                      Comprobante.{extension}
                    </Text>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <a
                      href={image}
                      download
                      target="_blank noopener noreferrer"
                    >
                      <Text
                        color="#1CA892"
                        style={{ marginRight: 7, marginBottom: 0 }}
                      >
                        Ver Comprobante
                      </Text>
                    </a>
                    {/* <Text
                      color="#1CA892"
                      onClick={() => setImage("")}
                      style={{ marginBottom: 0, cursor: "pointer" }}
                    >
                      Eliminar
                    </Text> */}
                  </Box>
                </Box>
              )}
            </>
          )}
          {status === "PAID" && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding="0 50px"
                marginBottom="40px"
              >
                <Box display="flex" alignItems="center">
                  <AiFillFile color="#1CA892" size="15px" />
                  <Text style={{ marginBottom: 0, marginLeft: 10 }}>
                    Comprobante.{extension}
                  </Text>
                </Box>
                <Box display="flex" alignItems="center">
                  <a href={image} download target="_blank noopener noreferrer">
                    <Text
                      color="#1CA892"
                      style={{ marginRight: 7, marginBottom: 0 }}
                    >
                      Ver Comprobante
                    </Text>
                  </a>
                  {/* <Text
                      color="#1CA892"
                      onClick={() => setImage("")}
                      style={{ marginBottom: 0, cursor: "pointer" }}
                    >
                      Eliminar
                    </Text> */}
                </Box>
              </Box>
            </>
          )}

          <Table
            columns={
              window.location.search.includes("Org")
                ? dataTable.columns
                : allyTable.columns
            }
            dataSource={invoiceDetails?.data}
            pagination={false}
            scroll={{ y: 362 }}
          />
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height="100px"
            padding="10px"
            position="relative"
          >
            <Pagination
              onShowSizeChange={onChangeItemsPerPage}
              defaultCurrent={1}
              current={pagination?.page}
              locale={{ items_per_page: "" }}
              total={pagination?.total}
              pageSizeOptions={[10, 20, 30]}
              onChange={onChangePage}
              pageSize={pagination?.itemsPerPage}
            />
          </Box>
        </BillingCard>
      )}
    </>
  );
};

const BillingCard = styled.div`
  width: 100%;
  height: ${(props) => props.height || "559px"};
  border-radius: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  height: 800px;
  display: flex;
  flex-direction: column;
`;

const UploadFile = styled(Upload)`
  .ant-upload {
    width: 100%;
    height: 146px;
    background: #76889a05 0% 0% no-repeat padding-box;
    border: 1px dashed #76889a80;
  }
`;

const SelectComp = styled(Select)`
  color: #fff !important;
  display: flex;
  align-items: center;
  height: 30px;
  width: fit-content;

  .ant-select-selector {
    background: ${(props) => getSelectBg(props.status)} !important;
    border-radius: 5px !important;
  }

  .ant-select-arrow {
    color: #fff;
  }
  .ant-select-selection-item {
    color: #fff;
  }
`;

const DivideSection = styled.div`
  height: 54px;
  background: #f7f7f7b7 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  margin-bottom: 20px;
`;

const BillingCardHeader = styled.div`
  padding: 40px 50px 30px;
`;

export default InvoicesDetailsSA;
