import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import profileImg from "../../assets/img/img-placeholder.svg";
import { Link } from "react-router-dom";
import {
  Text,
  LabelText,
} from "../../containers/dashboard-sa/Clients/NewClient";
import Spinner from "./Spinner";
import { StatusItem } from "../../containers/dashboard-sa/config/alliesTable";
import { useParams } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

const showInvoiceCycleText = (invoice) => {
  switch (invoice) {
    case "ONE_WEEK": {
      return "Una semana";
    }
    case "TWO_WEEK": {
      return "Dos semanas";
    }
    case "ONE_MONTH": {
      return "Un mes";
    }

    case "ONE_DAY": {
      return "Un dia";
    }
    default: {
      return "";
    }
  }
};

const ProfileInfo = ({ children, allyProfile, loadingProfile }) => {
  const params = useParams();
  return loadingProfile ? (
    <div
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 300,
      }}
    >
      <Spinner />
    </div>
  ) : (
    <>
      <Row>
        <Col span={12}>
          <Row>
            <Col>
              <ProfileImage src={allyProfile?.urlProfileImage || profileImg} />
            </Col>
            <Col>
              <ProfileName>{allyProfile?.name}</ProfileName>
              <ProfileLink to={ROUTES.editAllies({ id: allyProfile?.id })}>
                Editar perfil
              </ProfileLink>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <StatusItem status={allyProfile?.status}>
              {allyProfile?.status === "ENABLED" ? "Activo" : "Inactivo"}
            </StatusItem>
          </Row>
        </Col>
      </Row>
      <LabelText style={{ marginBottom: 10 }}>Información Basica</LabelText>
      <Row justify="space-between">
        <Col style={{ marginLeft: 10 }}>
          <Text>NIT</Text>
        </Col>
        <Col>
          <Text color="#0A181D">{allyProfile?.nit}</Text>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col style={{ marginLeft: 10 }}>
          <Text>Teléfono</Text>
        </Col>
        <Col>
          <Text color="#0A181D">
            {allyProfile?.phoneNumber.length === 10
              ? allyProfile?.phoneNumber.replace(
                  /(\d{3})(\d{3})(\d{4})/g,
                  "$1 $2 $3"
                )
              : allyProfile?.phoneNumber.replace(
                  /(\W)(\d{2})(\d{3})(\d{3})(\d{4})/g,
                  "$1$2 $3 $4 $5"
                )}
          </Text>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col style={{ marginLeft: 10 }}>
          <Text>Correo electrónico</Text>
        </Col>
        <Col>
          <Text color="#0A181D">{allyProfile?.email}</Text>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col style={{ marginLeft: 10 }}>
          <Text>Ciclo de facturación</Text>
        </Col>
        <Col>
          <Text color="#0A181D">
            {showInvoiceCycleText(allyProfile?.invoiceCycle)}
          </Text>
        </Col>
      </Row>
      {children}
    </>
  );
};

const ProfileImage = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: 40px;
  margin-right: 10px;
`;

const ProfileName = styled.h6`
  font-size: 18px;
  font-weight: bold;
  color: #051a55;
`;
const ProfileLink = styled(Link)`
  text-decoration: underline;
  font-size: 14px;
  color: #585858;
`;
export default ProfileInfo;
