import { createGlobalStyle } from "styled-components";

import AeonikBoldB from "../assets/fonts/Aeonik-Bold.otf";
import AeonikBoldR from "../assets/fonts/Aeonik-Regular.otf";
import AeonikBoldM from "../assets/fonts/Aeonik-Medium.otf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Aeonik";
    src: url(${AeonikBoldM});
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Aeonik";
    src: url(${AeonikBoldR});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Aeonik";
    src: url(${AeonikBoldB});
    font-weight: bold;
    font-style: normal;
  }
  body {
    font-family: "Aeonik";
  }
`;
