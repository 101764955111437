import React, { useContext, useEffect, useState } from "react";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import { useSelectableRow } from "../../dashboard/transporters/tableConfig";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import TransporterForm from "../../dashboard/transporters/TransporterForm";
import { StatusItem } from "../../../components/dashboard-SA/InvoicesItem";
import {
  getInvoices,
  getTransactionsTotalByPartner,
} from "../../../Services/firebase";
import { Context } from "../../../Context";
import moment from "moment";
import "moment-timezone";
import usePagination from "../../../hooks/usePagination";
import { DatePicker, Row, Col } from "antd";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { MdAttachMoney } from "react-icons/md";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text) => <span>{text}</span>,
    sortDirections: ["descend"],
  },

  {
    title: "Fecha de creación",
    dataIndex: "date",
    key: "date",

    sortDirections: ["descend"],
  },
  {
    title: "Rango de Facturación",
    dataIndex: "range",
    key: "range",

    sortDirections: ["descend"],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: ({ current, text }) => (
      <StatusItem status={current}>{text}</StatusItem>
    ),
    sortDirections: ["descend"],
  },
  {
    title: "Monto",
    dataIndex: "amount",
    key: "amount",
    sortDirections: ["descend"],
  },
];

const getTagColor = (status) => {
  if (status === "PENDING_CONFIRM_PAY") {
    return "error";
  }

  if (status === "PENDING_PAY") {
    return "warning";
  }

  if (status === "PAID") {
    return "success";
  }
};
const getTagText = (status) => {
  if (status === "PENDING_CONFIRM_PAY") {
    return "Pendiente por verificar";
  }

  if (status === "PENDING_PAY") {
    return "Pendiente que Hoy+ pague";
  }

  if (status === "PAID") {
    return "Pagado";
  }
};

const BillingAllies = ({ history }) => {
  const rowSelectable = useSelectableRow();
  const modal = useDashboardModal();
  const { currentUser } = useContext(Context);
  const [invoices, setInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();

  useEffect(() => {
    const onGetInvoices = async () => {
      try {
        setLoading(true);
        const { data } = await getInvoices(page, itemsPerPage, dateRange);
        const responseTotal = await getTransactionsTotalByPartner();
        setTotalAmount(
          `$ ${new Intl.NumberFormat("de-DE").format(responseTotal.data)}`
        );
        const formatedRows = data?.data?.map((row) => ({
          id: row.id,
          date: moment(row.createdAt).format("DD/MM/YYYY"),
          range: `${moment(row.initDate.split(".")[0]).format(
            "MMMM DD, hh:mm a"
          )} - ${moment(row.endDate.split(".")[0]).format("MMMM DD, hh:mm a")}`,
          amount: `$ ${new Intl.NumberFormat("de-DE").format(row.value)}`,
          status: {
            current: getTagColor(row.status),
            text: getTagText(row.status),
          },
        }));
        setPagination(data?.pagination);
        setInvoices(formatedRows);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    if (currentUser) {
      onGetInvoices();
    }
  }, [currentUser, itemsPerPage, page, setPagination, dateRange]);

  const onClickRow = (e, row) => {
    if (
      e.target.parentElement.className.includes("icons") ||
      e.target.tagName === "svg"
    ) {
      return;
    }
    history.push(`/invoice/${row.id}`);
  };

  return (
    <>
      <Row justify="end">
        <RangePicker
          defaultValue={[moment().subtract(30, "days"), moment()]}
          ranges={{
            Hoy: [moment(), moment()],
            "Mes en curso": [
              moment().startOf("month"),
              moment().endOf("month"),
            ],
            Semana: [moment().startOf("week"), moment().endOf("week")],
            "Últimos 30 días": [moment().subtract(30, "days"), moment()],
          }}
          onChange={(dates, datesString) =>
            setDateRange([
              moment(dates[0]).startOf("day").format(),
              moment(dates[1]).endOf("day").format(),
            ])
          }
        />
      </Row>
      <Row>
        <Col span={12}>
          <DashboardSectionTitle>Facturación</DashboardSectionTitle>
        </Col>
        {totalAmount && (
          <Col span={12}>
            <Row>
              <Col span={12} offset={12}>
                <DashboardItem
                  title="Total a cobrar a Hoy +"
                  value={totalAmount}
                  factor=""
                  icon={<MdAttachMoney />}
                  iconColor="#1D00FF"
                  iconBg="#4605F812"
                  factorStatus="up"
                  style={{ marginBottom: 30 }}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      <DashboardTable
        columns={columns}
        pagination={pagination}
        rowSelectable={rowSelectable}
        tableTitle="Lista de Facturas"
        buttonActionText="+ Crear transportador"
        data={invoices}
        page={page}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        loading={loading}
        onClickRow={onClickRow}
      />
      <TransporterForm modalSetting={modal} />
    </>
  );
};

export default BillingAllies;
