import React from "react";
import { Tag } from "antd";

export const columns = [
  {
    title: "ID Transacción",
    key: "id",
    dataIndex: "id",

    sortDirections: ["descend"],
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",

    sortDirections: ["descend"],
  },
  {
    title: "Sucursal",
    dataIndex: "branch",
    key: "branch",

    sortDirections: ["descend"],
  },
  {
    title: "Nombre del transportador",
    dataIndex: "driverName",
    key: "driverName",
    sortDirections: ["descend"],
  },

  {
    title: "Valor transferido",
    dataIndex: "mount",
    key: "mount",

    sortDirections: ["descend"],
  },
  {
    title: "Dian ID",
    dataIndex: "invoiceDiandId",
    key: "invoiceDiandId",

    sortDirections: ["descend"],
  },
  {
    title: "Estado",
    dataIndex: "transactionStatus",
    key: "transactionStatus",
    sortDirections: ["descend"],
    render: (status) => {
      let color;
      let statusText = "";
      switch (status) {
        case "COMPLETED":
          color = "green";
          statusText = "Completada";
          break;
        case "BLOCKED":
          color = "volcano";
          statusText = "Cancelado";
          break;
        case "PROCESSING":
          color = "geekblue";
          statusText = "Procesando";
          break;
        case "PENDING":
          color = "gold";
          statusText = "Pendiente";
          break;
        default:
          color = "geekblue";
          break;
      }
      return (
        <Tag color={color} key={status}>
          {statusText}
        </Tag>
      );
    },
  },
];
