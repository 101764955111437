import {useState, useRef, useEffect} from 'react';


export const useInfinityScroll = (data, setData, getDataFromServer, pagination, setPagination, clientId) => {
    const [loadingScroll, setLoadingScroll] = useState(false);
    const containerRef = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const handleInfinityScroll = async (elmRef) => {
    if (((elmRef.current.scrollHeight - Math.ceil(elmRef.current.scrollTop) <= elmRef.current.clientHeight) 
        && !loadingScroll) || pagination.total >= pagination.page){
            setLoadingScroll(true);
            try {
              const response = await getDataFromServer(pagination?.page + 1, pagination.itemsPerPage, null, clientId);
              setData([...data, ...response.data.data]);
              setPagination({...pagination, page: response.data.pagination.currentPage,
                itemsPerPage: response.data.pagination.itemsPerPage,
                total: response.data.pagination.totalPages,})
              setLoadingScroll(false);

            } catch(e) {
                setLoadingScroll(false);
                console.log(e.response);
            }
     }
    }

    const handleScrolling = () => {
        setIsScrolling(true);
    }
    
    useEffect(() => {
        
        let debounce = null;
        const refScroll = containerRef;
        if(isScrolling) {
            debounce = setTimeout(()=>{
                handleInfinityScroll(refScroll);
                setIsScrolling(false);
                
            }, 900);
        }
        
        return () => {
            if(debounce) {
               clearTimeout(debounce)
            }
        }
    }, [loadingScroll, isScrolling])

    return {
        loadingScroll: loadingScroll,
        setLoadingScroll: setLoadingScroll,
        containerRef: containerRef,
        handleScrolling: handleScrolling

    }
}