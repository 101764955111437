import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/dashboard-layout/button/Button';
import { DashboardSectionTitle } from '../../../components/dashboard-layout/styledComponents';
import DashboardTable from '../../../components/dashboard-layout/table/DashboardTable';
import { columns } from './tableConfig';
import { getPartnerBranches } from '../../../Services/firebase';
import { useHistory } from 'react-router-dom';
import usePagination from '../../../hooks/usePagination';

const Branchs = () => {
  const [branchs, setBranchs] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    page,
    itemsPerPage,
    onChangePage,
    onChangeItemsPerPage,
    setPagination,
    pagination,
  } = usePagination();
  const [searchBarActiveVal, setSearchbarActiveVal] = useState('');
  const history = useHistory();
  const onChangeSearchbar = (e) => {
    setSearchbarActiveVal(e.target.value);
  };
  const onGetBranchs = async (pag, filterVal) => {
    setLoading(true);
    try {
      const { data } = await getPartnerBranches(
        page,
        itemsPerPage,
        filterVal || ''
      );
      const formatedRows = data?.data?.map((row) => {
        const formatData = {
          id: row.id,
          name: row.name,
          email: row.email || '-',
          phone: row.phoneNumber || '-',
          address: row.address || '-',
          partnerId: row.PartnerId,
        };
        return formatData;
      });
      setBranchs(formatedRows);
      setPagination({
        ...pagination,
        page: data.pagination.currentPage,
        total: data.pagination.total,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleRowClick = (e, r) => {
    e.stopPropagation();
    history.push(`/ally/${r.partnerId}/branchs/${r.id}`);
  };

  useEffect(() => {
    let timeout = setTimeout(
      () => onGetBranchs(pagination, searchBarActiveVal),
      400
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [searchBarActiveVal, page, itemsPerPage]);

  return (
    <>
      <DashboardSectionTitle>Sucursales</DashboardSectionTitle>

      <DashboardTable
        columns={columns}
        tableTitle="Lista de sucursales"
        data={branchs}
        pagination={pagination}
        page={page}
        rowkey="key"
        loading={loading}
        onClickRow={handleRowClick}
        searchbarPlaceholder="Buscar sucursales"
        onChangeSearchBar={onChangeSearchbar}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onChangePage={onChangePage}
        customButton={
          <Button type="primary">
            <Link to={'/branchs/create'}>{'+ Crear sucursal'}</Link>
          </Button>
        }
      />
    </>
  );
};

export default Branchs;
