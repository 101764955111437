import React, { useState, useEffect } from "react";
import {
  DashboardSectionTitle,
  DashboardCard,
  DashboardCardText,
  Text as TextDash,
  InputLayout,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import { Row, Col, Spin, Tag, Breadcrumb } from "antd";
import Button from "../../../components/dashboard-layout/button/Button";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import { FaPlusCircle } from "react-icons/fa";
import TransporterForm from "../transporters/TransporterForm";
import useInput from "../../../hooks/useInput";
import {
  onSearchDriver,
  onGetServiceOrder,
  onGetServiceOrderLog,
  onEditServiceOrder,
} from "../../../Services/firebase";
import { withRouter } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../../Context";
import { BreadcrumItem } from "../../dashboard-sa/Clients/NewClient";
import DropdownSearch from "../../../components/dashboard-SA/DropdownSearch";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import RemaningPaymentModal from "./RemainingPaymentForm";
import { LoadingOutlined } from "@ant-design/icons";
import ServiceOrderHistory from "./ServiceOrderHistory";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";

const antIconLoading = (
  <LoadingOutlined style={{ fontSize: 20, color: "#1645B8" }} spin />
);

function EditServiceOrder({ view, match, history }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serviceOrder, setServiceOrder] = useState(null);
  const { authUser } = useContext(Context);
  const manifest = useInput("", isSubmitting);
  const totalService = useInput("", isSubmitting);
  const advancePercentage = useInput("", isSubmitting);
  const [loadingCreateServiceOrder, setLoadingCreateServiceOrder] = useState(
    false
  );
  const [loadingServiceOrder, setLoadingServiceOrder] = useState(false);
  const [loadingAdvance, setLoadingAdvance] = useState(false);
  const [loadingLog, setLoadingLog] = useState(false);
  const modal = useDashboardModal();
  const modalRemainingPayment = useDashboardModal();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [log, setLog] = useState([]);
  const { params } = match;

  useEffect(() => {
    const getServiceOrder = async () => {
      try {
        setLoadingServiceOrder(true);
        const { data } = await onGetServiceOrder(params.id);
        setServiceOrder(data);
        setSelectedUser(data.Driver);
        manifest.onSetValue(data.manifestNumber);
        totalService.onSetValue(data?.total);
        advancePercentage.onSetValue(data.advancePercentage);
        setLoadingServiceOrder(false);
      } catch (error) {
        setLoadingServiceOrder(false);

        console.log(error);
      }
    };

    if (match.params?.id && authUser) {
      getServiceOrder();
    }
  }, [authUser, match]);
  useEffect(() => {
    const getServiceLog = async () => {
      try {
        setLoadingLog(true);
        const { data } = await onGetServiceOrderLog(
          1,
          10,
          parseInt(match.params.id)
        );
        setLog(data.data);
        setLoadingLog(false);
      } catch (error) {
        setLoadingLog(false);

        console.log(error);
      }
    };

    if (serviceOrder) {
      //getDriver();
      getServiceLog();
    }
  }, [match.params.id, serviceOrder]);

  const handleSearch = async (e) => {
    const { value } = e.target;
    if (value) {
      try {
        setLoadingUsers(true);
        const { data } = await onSearchDriver(value);
        setUsers(data);
        setLoadingUsers(false);
      } catch (error) {
        setLoadingUsers(false);
        console.log(error);
      }
    } else {
      setUsers([]);
    }
  };

  const renderStatusText = () => {
    if (serviceOrder?.status === "CREATED") {
      return { text: "Orden de servicio creada", color: "gold" };
    }
  };

  const handleSelect = (idNumber) => {
    setSelectedUser(users.find((user) => user.idNumber === idNumber));
  };

  const editServiceOrder = async () => {
    setIsSubmitting(true);
    if (
      !manifest.value ||
      !totalService.value ||
      !advancePercentage.value ||
      !selectedUser
    ) {
      return;
    }
    try {
      setLoadingCreateServiceOrder(true);
      const { data } = await onEditServiceOrder(
        manifest.value,
        +totalService.value,
        +advancePercentage.value,
        +selectedUser.id,
        parseInt(params.id)
      );
      toast.success("Orden de servicio editada satisfactoriamente");
      history.push(`/service-order/${params.id}`);
      setLoadingCreateServiceOrder(false);
    } catch (error) {
      setLoadingCreateServiceOrder(false);
      if (error.details && error.details.length) {
        if (error?.details[0].message === "manifestNumber must be unique") {
          toast.error("El número de manifiesto debe ser único");
        }
        return;
      }
      toast.error(error?.message || "Error inesperado");
    }
  };

  return (
    <>
      <FlexContainerHelper justify="space-between">
        <div>
          <DashboardSectionTitle>
            Editar Orden de Servicio #{params.id}
          </DashboardSectionTitle>
          <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
            <BreadcrumItem active href="/">
              Dashboard
            </BreadcrumItem>
            <BreadcrumItem> Editar orden de servicio</BreadcrumItem>
          </Breadcrumb>
        </div>
        {!loadingCreateServiceOrder ? (
          <Button type="success" actionButton={editServiceOrder}>
            Guardar Solicitud de Servicios
          </Button>
        ) : (
          <Spin indicator={antIconLoading} />
        )}
      </FlexContainerHelper>

      <DashboardCard direction={"column"}>
        {loadingServiceOrder ? (
          <Spin indicator={antIconLoading} />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DashboardCardText>
                {view !== "view"
                  ? "Para crear una orden de servicio, necesitas un numero de manifiesto y la cedula del transportador que va a prestar el servicio. Luego de crearla, podras generar el anticipo y pago restante."
                  : "Tu orden de servicio ya esta creada, ahora puedes generar el anticipo a este transportador."}
              </DashboardCardText>
              {view === "view" && (
                <Tag
                  color={renderStatusText()?.color}
                  style={{ padding: "5px 10px", border: 0, borderRadius: 5 }}
                >
                  {renderStatusText()?.text}
                </Tag>
              )}
            </div>
            {view === "view" && (
              <DashboardSectionTitle>
                Solicitud de Servicio ID: {serviceOrder?.id}
              </DashboardSectionTitle>
            )}

            <Row gutter={[24, 16]}>
              <Col xs={{ span: 6 }}>
                <label>Número de manifiesto</label>
                <InputLayout
                  type="text"
                  placeholder="Ingresar numero de manifiesto"
                  size="large"
                  readOnly={view === "view"}
                  value={manifest.value}
                  onChange={manifest.onChange}
                />
                <ErrorMessage error={manifest.error}>
                  {manifest.error}
                </ErrorMessage>
              </Col>
              <Col xs={{ span: 6 }}>
                <DropdownSearch
                  onChange={handleSearch}
                  onSelect={handleSelect}
                  data={users}
                  loading={loadingUsers}
                  disabled={view === "view"}
                  selected={selectedUser}
                  removeSelectedUser={() => setSelectedUser(null)}
                />
                <ErrorMessage error={isSubmitting && !selectedUser}>
                  {isSubmitting &&
                    !selectedUser &&
                    "Por favor seleccione un conductor"}
                </ErrorMessage>
                {view !== "view" && (
                  <>
                    <TextDash
                      color="#00BE6D"
                      display="flex"
                      alignment="flex-end"
                    >
                      <FaPlusCircle />
                      <a href="#" onClick={modal.showModal}>
                        Agregar un conductor
                      </a>
                    </TextDash>
                    <TransporterForm modalSetting={modal} />
                  </>
                )}
              </Col>
              <Col xs={{ span: 4 }}>
                <label>Valor total del servicio</label>
                <CurrencyFormat
                  value={totalService?.value}
                  customInput={InputLayout}
                  thousandSeparator={"."}
                  decimalSeparator=","
                  readOnly={view === "view"}
                  prefix={"$"}
                  size="large"
                  placeholder="$ 0.00"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    totalService.setValue(floatValue);
                  }}
                />
                <ErrorMessage error={totalService.error}>
                  {totalService.error}
                </ErrorMessage>
              </Col>
              <Col xs={{ span: 4 }}>
                <label>Porcentaje de anticipo</label>
                <InputLayout
                  type="text"
                  placeholder="0"
                  maxLength={2}
                  suffix="%"
                  size="large"
                  readOnly={view === "view"}
                  max="100"
                  value={advancePercentage.value}
                  onChange={advancePercentage.onChange}
                />
                <ErrorMessage error={advancePercentage.error}>
                  {advancePercentage.error}
                </ErrorMessage>
              </Col>
            </Row>
            {view === "view" && (
              <>
                {serviceOrder?.status === "PAYMENT" && (
                  <RemaningPaymentModal
                    modalSetting={{
                      ...modalRemainingPayment,
                      title:
                        "Esta seguro que quiere generar el pago de este anticipo?",
                      handleSearch,
                      handleSelect,
                      users,
                      selectedUser,
                      setSelectedUser,
                      serviceOrder,
                      setServiceOrder,
                    }}
                  />
                )}
              </>
            )}

            <ServiceOrderHistory
              log={log}
              loadingLog={loadingLog}
              antIconLoading={antIconLoading}
              serviceOrder={serviceOrder}
            />
          </>
        )}
      </DashboardCard>
    </>
  );
}

export default withRouter(EditServiceOrder);
