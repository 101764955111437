import React, { useState } from "react";
import { Row } from "antd";
import {
  InputLayout,
  FlexContainerHelper,
} from "../../../components/dashboard-layout/styledComponents";
import Button from "../../../components/dashboard-layout/button/Button";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";
import useInput from "../../../hooks/useInput";
import { inviteUser } from "../../../Services/firebase";
import Spinner from "../../../components/dashboard-SA/Spinner";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const emailRgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function BranchUserForm({ modalSetting }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const email = useInput("", isSubmitting);
  const name = useInput("", isSubmitting);
  const params = useParams();
  const onInviteUser = async () => {
    setIsSubmitting(true);
    if (!email.value) {
      return;
    }

    if (!emailRgx.test(email.value)) {
      email.setError("Por favor, Ingresa un Correo electrónico válido");
      return;
    }

    try {
      setLoading(true);
      const { data } = await inviteUser(
        email.value,
        "PARTNER_BRANCH_ADMIN",
        params.id
      );
      /// updateDrivers(data);
      toast.success(`Se ha enviado una invitacion a ${email.value}`);
      email.setValue("");
      name.setValue("");
      modalSetting.handleCancel();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message.includes("already exists")) {
        email.setError("Este usuario ya esta invitado!");
      }
      toast.error(`${error.message || error.data.error || "Error inesperado"}`);
      console.log(error.message);
    }
  };

  return (
    <DashboardModal {...modalSetting} footer={null}>
      <p>
        Para agregar un usuario ingresa el correo electrónico, se le enviara una
        invitación para que acceda a la aplicación.
      </p>
      <Row style={{ marginBottom: "50px" }}>
        <label>Correo Electrónico del usuario</label>
        <InputLayout
          type="email"
          placeholder="Ingresar correo electrónico"
          size="large"
          value={email.value}
          onChange={(e) => {
            email.onChange(e);
          }}
        />
        <ErrorMessage error={!!email.error}>{email.error}</ErrorMessage>
      </Row>
      <div>
        <FlexContainerHelper justify="flex-end">
          <Button type="alternative">Cancel</Button>
          <Button type="primary" actionButton={onInviteUser}>
            {loading ? <Spinner color="#ffF" /> : "Enviar invitación"}
          </Button>
        </FlexContainerHelper>
      </div>
    </DashboardModal>
  );
}

export default BranchUserForm;
