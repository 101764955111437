import React, { useEffect, useState } from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { Breadcrumb } from "antd";
import {
  BreadcrumItem,
  DashboardSaCard,
  ButtonsContainer,
} from "../Clients/NewClient";
import styled from "styled-components";
import Button from "../../../components/dashboard-layout/button/Button";
import { IoMdTrash } from "react-icons/io";
import { MdBlock } from "react-icons/md";
import { VerticalDivider } from "./NewAlly";
import AllyTransactions from "../../../components/dashboard-SA/AllyTransactions";
import ProfileInvoices from "../../../components/dashboard-SA/ProfileInvoices";
import { Tabs, Row, Col, DatePicker } from "antd";
import moment from "moment";

import ProfileInfo from "../../../components/dashboard-SA/ProfileInfo";
import {
  getAllyTransactions,
  getInvoices,
  onGetAllyProfile,
  onBlockPartner,
} from "../../../Services/firebase";
import Box from "../../../components/Box";
import AllyProducts from "./AllyProducts";
import Transactions from "./Transactions";
import { toast } from "react-toastify";
import Spinner from "../../../components/dashboard-SA/Spinner";

const blockIcon = () => <MdBlock size={12} color="#E4032B" />;
const unblockIcon = () => <MdBlock size={12} color="#00BE6D" />;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const AllyProfile = ({ match }) => {
  const [allyProfile, setAllyProfile] = useState(null);
  const [allyInvoices, setAllyInvoices] = useState([]);
  const [allyTransactions, setAllyTransactions] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [loadingTransaction, setLoadingTransactions] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const getAllyInvoices = async () => {
    try {
      setLoadingInvoices(true);
      const { data } = await getInvoices(
        1,
        10,
        dateRange,
        parseInt(match.params.id),
        "PARTNER"
      );

      const invoices = data.data.map((invoice, i) => {
        if (invoice.status === "PAID") {
          return { ...invoice, statusColor: "success", statusName: "Pagado" };
        }

        if (invoice.status === "PENDING_CONFIRM_PAY") {
          return {
            ...invoice,
            statusColor: "error",
            statusName: "Pendiente por confirmar pago",
          };
        }
        if (invoice.status === "PENDING_PAY") {
          return {
            ...invoice,
            statusColor: "warning",
            statusName: "Pendiente por pago",
          };
        }
      });
      setAllyInvoices(invoices);
      setLoadingInvoices(false);
    } catch (error) {
      setLoadingInvoices(false);

      console.log(error);
    }
  };
  useEffect(() => {
    const getAlly = async () => {
      try {
        setLoadingProfile(true);
        const { data } = await onGetAllyProfile(match.params.id);
        setAllyProfile(data);
        setLoadingProfile(false);
      } catch (error) {
        console.log(error);
        setLoadingProfile(false);
      }
    };

    const onGetAllyTranscations = async () => {
      try {
        setLoadingTransactions(true);
        const { data } = await getAllyTransactions(
          1,
          10,
          parseInt(match.params.id),
          "PARTNER"
        );
        setAllyTransactions(data.response.data);
        setLoadingTransactions(false);
      } catch (error) {
        console.log(error);
        setLoadingTransactions(false);
      }
    };
    onGetAllyTranscations();
    getAllyInvoices();
    getAlly();
  }, [match.params.id]);

  useEffect(() => {
    getAllyInvoices();
  }, [dateRange]);

  const blockPartner = async (status) => {
    setBlockLoading(true);
    try {
      await onBlockPartner(status, match.params.id);
      if (status === "DISABLED") {
        toast.error("Aliado bloqueado");
        setAllyProfile({ ...allyProfile, status: "DISABLED" });
      } else {
        toast.success("Aliado reactivado");
        setAllyProfile({ ...allyProfile, status: "ENABLED" });
      }
      setBlockLoading(false);
    } catch (e) {
      console.log(e);
      setBlockLoading(false);
    }
  };
  return (
    <>
      <Row align="middle">
        <Col span={12}>
          <DashboardSectionTitle>
            Perfil del aliado ID#001
          </DashboardSectionTitle>
          <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
            <BreadcrumItem active href="/allies">
              Aliados
            </BreadcrumItem>
            <BreadcrumItem>Perfil del aliado</BreadcrumItem>
          </Breadcrumb>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
        </Col>
      </Row>

      <DashboardSaCard height="auto" style={{ padding: "0px 16px" }}>
        <Tabss
          defaultActiveKey="1"
          onChange={(activeKey) => setCurrentTab(parseInt(activeKey))}
        >
          <TabPane tab="Perfil" key="1">
            <Box display="flex">
              <Box flex="1">
                <ProfileInfo
                  allyProfile={allyProfile}
                  loadingProfile={loadingProfile}
                />
              </Box>
              <Box>
                <VerticalDivider height={500} style={{ margin: "0 20px" }} />
              </Box>
              <Box flex="1">
                <ProfileInvoices
                  items={allyInvoices}
                  title={"Facturas"}
                  loadingInvoices={loadingInvoices}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <ButtonsContainer>
                {!blockLoading ? (
                  <>
                    {allyProfile?.status === "ENABLED" ? (
                      <Button
                        actionButton={() => blockPartner("DISABLED")}
                        icon={blockIcon()}
                        type={"red_secondary"}
                      >
                        Bloquear perfil
                      </Button>
                    ) : (
                      <Button
                        actionButton={() => blockPartner("ENABLED")}
                        icon={unblockIcon()}
                        type={"success_secondary"}
                      >
                        Reactivar perfil
                      </Button>
                    )}
                  </>
                ) : (
                  <Spinner />
                )}
              </ButtonsContainer>
            </Box>
          </TabPane>
          <TabPane tab="Transacciones" key="2">
            <Transactions
              dateFilter={{ startDate: dateRange[0], endDate: dateRange[1] }}
            />
          </TabPane>
          <TabPane tab="Productos" key="3">
            <AllyProducts />
          </TabPane>
        </Tabss>
      </DashboardSaCard>
    </>
  );
};

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Tabss = styled(Tabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-weight: 500;
    min-width: 100px !important;
    text-align: center !important;
  }
`;

export default AllyProfile;
