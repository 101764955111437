import React, { useState } from "react";
import { Table, Row, Col, Pagination } from "antd";
import { TableContainer, TableToolbar } from "./styledComponents";
import { DashboardCardTitle } from "../styledComponents";
import SearchBar from "../search-bar/SearchBar";
// import { FaFilter } from "react-icons/fa";
// import Button from "../button/Button";
// import { Link } from "react-router-dom";
import Box from "../../Box";
import styled from "styled-components";

export const DashboardTable = ({
  columns,
  loading,
  tableTitle,
  data,
  customButton,
  onClickRow,
  rowkey,
  marginTop,
  page,
  pagination,
  onChangeItemsPerPage,
  onChangePage,
  searchbarPlaceholder = "Buscar solicitudes de servicio...",
  onChangeSearchBar,
  rowClassName,
  className,
}) => {
  const [tableConfig] = useState({ responsive: false });
  const scroll = tableConfig.responsive ? { x: "100vw" } : null;

  return (
    <TableContainer style={{ marginTop }} className={className}>
      <Row gutter={{ xs: 4 }} align={"middle"} style={{ marginBottom: "15px" }}>
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <DashboardCardTitle>{tableTitle}</DashboardCardTitle>
        </Col>
        <TableToolbar xs={{ span: 24 }} sm={{ span: 18 }}>
          <SearchBar
            pholder={searchbarPlaceholder}
            bcolor={"#F6F9FE"}
            elmwidth="40%"
            onChange={onChangeSearchBar}
          />
          {/* <Button type="secondary" icon={<FaFilter />}>
            Filter
          </Button> */}
          {customButton || null}
        </TableToolbar>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        scroll={scroll}
        pagination={false}
        rowKey={rowkey || "id"}
        loading={loading}
        rowClassName={rowClassName}
        showSizeChanger
        onRow={(record, rowIndex) => {
          if (!onClickRow) {
            return;
          }
          return {
            onClick: (event) => onClickRow(event, record), // click row
          };
        }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="100px"
        padding="10px 0"
        position="relative"
      >
        <span>Mostrar: </span>
        <TablePagination
          showSizeChanger
          onShowSizeChange={onChangeItemsPerPage}
          defaultCurrent={1}
          current={page}
          locale={{ items_per_page: "" }}
          total={pagination?.total}
          pageSizeOptions={[10, 20, 30]}
          onChange={onChangePage}
          pageSize={pagination?.pageSize || 10}
        />
      </Box>
    </TableContainer>
  );
};

const TablePagination = styled(Pagination)`
  & .ant-pagination-options {
    position: absolute;
    left: 40px;
  }
`;

export default DashboardTable;
