import React, { useEffect, useState } from "react";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { Link } from "react-router-dom";
import Button from "../../../components/dashboard-layout/button/Button";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { useSelectableAlliesRow, alliesColumns } from "../config/alliesTable";
import { onGetAllies } from "../../../Services/firebase";

const Allies = ({ history }) => {
  const rowSelectable = useSelectableAlliesRow();
  const [alliesData, setAlliesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const tableActionButtons = (
    <>
      <Link to="/allies/create">
        <Button type="primary">+ Crear nuevo aliado</Button>
      </Link>
    </>
  );
  const [searchVal, setSearchVal] = useState("");
  const onClickRow = (e, row) => {
    history.push(`/allies/${row.id}`);
  };

  useEffect(() => {
    const getAllies = async () => {
      try {
        setLoading(true);
        const { data } = await onGetAllies(pagination.page, pagination.pageSize, searchVal);
        const allies = data.data.map((ally) => ({
          id: ally.id,
          name: ally.name,
          phoneNumber: ally.phoneNumber,
          nit: ally.nit || "-",
          email: ally.email,
          status: ally.status,
        }));

        setAlliesData(allies);
        setPagination({
          ...pagination,
          page: data.pagination.currentPage,
          total: data.pagination.total,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    };

    getAllies();
  }, [pagination.page, pagination.pageSize, searchVal]);

  const onChangePage = (page) => setPagination({...pagination, page}); 
  const onChangePageSize = (index, value) => setPagination({...pagination, pageSize: value}); 
  const onChangeSearchBar = (e) => setSearchVal(e.target.value);
  return (
    <>
      <DashboardSectionTitle>Aliados</DashboardSectionTitle>

      <DashboardTable
        columns={alliesColumns}
        rowSelectable={rowSelectable}
        tableTitle="Lista de Aliados"
        data={alliesData}
        onClickRow={onClickRow}
        loading={loading}
        customButton={tableActionButtons}
        page={pagination.page}
        pagination={pagination}
        onChangePage={onChangePage}
        onChangeItemsPerPage={onChangePageSize}
        onChangeSearchBar={onChangeSearchBar}
      />
    </>
  );
};

export default Allies;
