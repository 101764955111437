import React, { useState, useEffect } from "react";
import {
  DashboardSectionTitle,
  DashboardCard,
  DashboardCardText,
  Text as TextDash,
  InputLayout,
  FlexContainerHelper,
  HistoryItem,
} from "../../../components/dashboard-layout/styledComponents";
import { Row, Col, Divider, Timeline, Spin, Tag, Breadcrumb } from "antd";
import Button from "../../../components/dashboard-layout/button/Button";
import { useDashboardModal } from "../../../components/dashboard-layout/modal/util";
import { FaPlusCircle } from "react-icons/fa";
import TransporterForm from "../transporters/TransporterForm";
import useInput from "../../../hooks/useInput";
import {
  onSearchDriver,
  onCreateServiceOrder,
  onGetServiceOrder,
  onCreatePaymentServiceOrder,
  onGetServiceOrderLog,
} from "../../../Services/firebase";
import { withRouter } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useContext } from "react";
import { Context } from "../../../Context";
import ServiceModal from "./ConfirnService";
import { BreadcrumItem, Text } from "../../dashboard-sa/Clients/NewClient";
import DropdownSearch from "../../../components/dashboard-SA/DropdownSearch";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import moment from "moment";
import RemaningPaymentModal from "./RemainingPaymentForm";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);

const antIconLoading = (
  <LoadingOutlined style={{ fontSize: 20, color: "#1645B8" }} spin />
);

function ServiceOrders({ view, match, history }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serviceOrder, setServiceOrder] = useState(null);
  const { authUser, currentUser } = useContext(Context);
  const manifest = useInput("", isSubmitting);
  const totalService = useInput("", isSubmitting);
  const advancePercentage = useInput("", isSubmitting);
  const [loadingCreateServiceOrder, setLoadingCreateServiceOrder] = useState(
    false
  );
  const [loadingServiceOrder, setLoadingServiceOrder] = useState(false);
  const [loadingAdvance, setLoadingAdvance] = useState(false);
  const [loadingLog, setLoadingLog] = useState(false);
  const modal = useDashboardModal();
  const modalService = useDashboardModal();
  const modalRemainingPayment = useDashboardModal();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [log, setLog] = useState([]);
  const { params } = match;

  useEffect(() => {
    const getServiceOrder = async () => {
      try {
        setLoadingServiceOrder(true);
        const { data } = await onGetServiceOrder(params.id);
        setServiceOrder(data);
        setLoadingServiceOrder(false);
      } catch (error) {
        setLoadingServiceOrder(false);

        console.log(error);
      }
    };

    if (match.params?.id && authUser) {
      getServiceOrder();
    }
  }, [authUser, match]);
  useEffect(() => {
    const getDriver = async () => {
      try {
        setLoadingServiceOrder(true);
        const { data } = await onSearchDriver(serviceOrder.Driver?.idNumber);
        setSelectedUser(data[0]);
        setLoadingServiceOrder(false);
      } catch (error) {
        console.log(error);
        setLoadingServiceOrder(false);
      }
    };

    const getServiceLog = async () => {
      try {
        setLoadingLog(true);
        const { data } = await onGetServiceOrderLog(
          1,
          10,
          parseInt(match.params.id)
        );
        setLog(data.data);
        setLoadingLog(false);
      } catch (error) {
        setLoadingLog(false);

        console.log(error);
      }
    };

    if (serviceOrder) {
      getDriver();
      getServiceLog();
    }
  }, [match.params.id, serviceOrder]);

  const handleSearch = async (e) => {
    const { value } = e.target;
    if (value) {
      try {
        setLoadingUsers(true);
        const { data } = await onSearchDriver(value);
        setUsers(data);
        setLoadingUsers(false);
      } catch (error) {
        setLoadingUsers(false);
        console.log(error);
      }
    } else {
      setUsers([]);
    }
  };

  const renderStatusText = () => {
    if (serviceOrder?.status === "CREATED") {
      return { text: "Orden de servicio creada", color: "gold" };
    }

    if (serviceOrder?.status === "PAYMENT") {
      return { text: "Anticipo generado", color: "volcano" };
    }

    if (serviceOrder?.status === "COMPLETED") {
      return { text: "Orden de servicio finalizada", color: "green" };
    }
  };

  const handleSelect = (idNumber) => {
    setSelectedUser(users.find((user) => user.idNumber === idNumber));
  };

  const createServiceOrder = async () => {
    setIsSubmitting(true);
    if (
      !manifest.value ||
      !totalService.value ||
      !advancePercentage.value ||
      !selectedUser
    ) {
      return;
    }
    try {
      setLoadingCreateServiceOrder(true);
      const { data } = await onCreateServiceOrder(
        manifest.value,
        +totalService.value,
        +advancePercentage.value,
        +selectedUser.id
      );
      history.push(`/service-order/${data.id}`);
      setLoadingCreateServiceOrder(false);
      toast.success("Orden de servicio creada satisfactoriamente");
    } catch (error) {
      setLoadingCreateServiceOrder(false);
      if (error.code === 412) {
        toast.error(
          "Número de manifiesto duplicado. Por favor, insertar uno diferente."
        );
      } else {
        toast.error(
          error.message ||
            "Ha ocurrido un error inesperado en el servidor contacta a soporte."
        );
      }
    }
  };

  const renderAdvanceTittle = () => {
    const advance =
      (+serviceOrder?.total * +serviceOrder?.advancePercentage) / 100;

    if (!isNaN(advance)) {
      return (
        <p style={{ fontSize: 18, margin: 0 }}>
          Confirmar anticipo por valor de{" "}
          <strong style={{ color: "#1645B8" }}>
            $
            {new Intl.NumberFormat("de-DE").format(
              advance.toString().replace(/[\D\s\._\-]+/g, "")
            )}{" "}
          </strong>{" "}
          ha{" "}
          <strong style={{ color: "#1645B8" }}>
            {serviceOrder?.Driver?.User?.firstName}{" "}
            {serviceOrder?.Driver?.User?.lastName} C.I{" "}
            {serviceOrder?.Driver?.idNumber}
          </strong>
        </p>
      );
    }
  };

  const onFirstPayment = async () => {
    try {
      setLoadingAdvance(true);
      const { data } = await onCreatePaymentServiceOrder(
        serviceOrder?.id,
        serviceOrder?.DriverId
      );

      setServiceOrder({ ...serviceOrder, ...data });
      setLoadingAdvance(false);
    } catch (error) {
      setLoadingAdvance(false);

      console.log(error);
    }
  };

  const editBtn =
    view === "view" ? (
      <Button type="success">
        <Link className="link-btn" to={`/service-order/edit/${params.id}`}>
          Editar Solicitud de Servicios
        </Link>
      </Button>
    ) : null;

  const createBtns = () => {
    if (view === "create") {
      return (
        <Row gutter={[8, 16]} style={{ justifyContent: "flex-end" }}>
          <Col
            xs={{ span: 4 }}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button type={"alternative"} actionButton={() => history.push("/")}>
              Cancel
            </Button>
          </Col>
          <Col xs={{ span: 6 }}>
            <Button
              type={"primary"}
              actionButton={createServiceOrder}
              disabled={loadingCreateServiceOrder}
            >
              {loadingCreateServiceOrder ? (
                <Spin indicator={antIcon} />
              ) : (
                "Crear Solicitud de Servicio"
              )}
            </Button>
          </Col>
        </Row>
      );
    }

    if (serviceOrder?.status === "PAYMENT") {
      return (
        <Row gutter={[8, 16]} style={{ justifyContent: "flex-end" }}>
          <Col xs={{ span: 6 }}>
            <Button
              type={"primary"}
              actionButton={modalRemainingPayment.showModal}
            >
              Generar pago restante
            </Button>
          </Col>
        </Row>
      );
    }

    if (serviceOrder?.status === "CREATED") {
      return (
        <Row gutter={[8, 16]} style={{ justifyContent: "flex-end" }}>
          <Col xs={{ span: 6 }}>
            <Button type={"primary"} actionButton={modalService.showModal}>
              Generar anticipo
            </Button>
          </Col>
        </Row>
      );
    }
  };

  const serviceOrderHistory = () => (
    <>
      <Divider />
      <DashboardSectionTitle style={{ fontSize: 16 }}>
        Historial
      </DashboardSectionTitle>
      {loadingLog ? (
        <Spin indicator={antIconLoading} />
      ) : (
        <HistoryItem>
          <Timeline>
            {log.length > 0 &&
              log.reverse().map((item, idx) => {
                if (idx === 0) {
                  return (
                    <Timeline.Item
                      dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
                      color="#00BE6D"
                      key={idx}
                    >
                      <DashboardCardText
                        style={{
                          width: 753,
                          color: "#0A181D",
                          marginBottom: 5,
                        }}
                      >
                        Solicitud <strong>#{item.ServiceOrderId}</strong> creada
                        por{" "}
                        <strong>
                          {`${currentUser?.firstName} ${currentUser?.lastName}` ||
                            "Dashboard User"}{" "}
                          - {authUser?.email}
                        </strong>{" "}
                        para el manifiesto{" "}
                        <strong>#{serviceOrder?.manifestNumber}</strong>{" "}
                        Conductor{" "}
                        <strong>
                          {item.data?.data?.driverFirtsName}{" "}
                          {item.data?.data?.driverLastName}
                        </strong>
                        con un porcentaje de anticipo de{" "}
                        <>{serviceOrder?.advancePercentage}%</>
                      </DashboardCardText>
                      <Text size={12} color="#949494" style={{ margin: 0 }}>
                        {moment(item.createdAt).format("MMM DD - hh:mm a")}
                      </Text>
                    </Timeline.Item>
                  );
                }

                if (idx === 1) {
                  return (
                    <Timeline.Item
                      dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
                      color="#00BE6D"
                      key={idx}
                    >
                      <DashboardCardText
                        style={{
                          width: 753,
                          color: "#0A181D",
                          marginBottom: 5,
                        }}
                      >
                        Anticipo generado por
                        {`${currentUser?.firstName} ${currentUser?.lastName}` ||
                          "Dashboard User"}{" "}
                        para el manifiesto{" "}
                        <strong>#{serviceOrder?.manifestNumber}.</strong>
                      </DashboardCardText>
                      <Text size={12} color="#949494" style={{ margin: 0 }}>
                        {moment(item.createdAt).format("MMM DD - hh:mm a")}
                      </Text>
                    </Timeline.Item>
                  );
                }

                if (idx > 1) {
                  return (
                    <Timeline.Item
                      dot={<AiOutlineCheckCircle size={20} color="#00BE6D" />}
                      color="#00BE6D"
                      key={idx}
                    >
                      <DashboardCardText
                        style={{
                          width: 753,
                          color: "#0A181D",
                          marginBottom: 5,
                        }}
                      >
                        Pago restante generado por{" "}
                        {`${currentUser?.firstName} ${currentUser?.lastName}` ||
                          "Dashboard User"}{" "}
                        al conductor{" "}
                        <strong>
                          {item.data?.data?.driverFirtsName}{" "}
                          {item.data?.data?.driverLastName}{" "}
                        </strong>
                        por un monto de{" "}
                        <strong>
                          $
                          {new Intl.NumberFormat("de-DE").format(
                            item.data?.data?.transactionValue
                          )}
                        </strong>
                      </DashboardCardText>
                      <Text size={12} color="#949494" style={{ margin: 0 }}>
                        {moment(item.createdAt).format("MMM DD - hh:mm a")}
                      </Text>
                    </Timeline.Item>
                  );
                }
              })}
          </Timeline>
        </HistoryItem>
      )}
    </>
  );

  return (
    <>
      <FlexContainerHelper justify="space-between">
        <div>
          {view === "create" && (
            <DashboardSectionTitle>
              Crear Orden de Servicio
            </DashboardSectionTitle>
          )}
          {view === "edit" && (
            <DashboardSectionTitle>
              Editar Orden de Servicio #{params.id}
            </DashboardSectionTitle>
          )}
          {view === "view" && (
            <DashboardSectionTitle>
              Orden de Servicio #{params.id}
            </DashboardSectionTitle>
          )}
          <Breadcrumb separator=">" style={{ marginBottom: 20 }}>
            <BreadcrumItem active href="/">
              Dashboard
            </BreadcrumItem>
            {view === "create" && (
              <BreadcrumItem> Crear orden de servicio</BreadcrumItem>
            )}
            {view === "edit" && (
              <BreadcrumItem> Editar orden de servicio</BreadcrumItem>
            )}
            {view === "view" && (
              <BreadcrumItem> Orden de servicio #{params.id}</BreadcrumItem>
            )}
          </Breadcrumb>
        </div>
        {editBtn}
      </FlexContainerHelper>

      <DashboardCard direction={"column"}>
        {loadingServiceOrder ? (
          <Spin indicator={antIconLoading} />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DashboardCardText>
                {view !== "view"
                  ? "Para crear una orden de servicio, necesitas un numero de manifiesto y la cedula del transportador que va a prestar el servicio. Luego de crearla, podras generar el anticipo y pago restante."
                  : "Tu orden de servicio ya esta creada, ahora puedes generar el anticipo a este transportador."}
              </DashboardCardText>
              {view === "view" && (
                <Tag
                  color={renderStatusText()?.color}
                  style={{ padding: "5px 10px", border: 0, borderRadius: 5 }}
                >
                  {renderStatusText()?.text}
                </Tag>
              )}
            </div>
            {view === "view" && (
              <DashboardSectionTitle>
                Solicitud de Servicio ID: {serviceOrder?.id}
              </DashboardSectionTitle>
            )}

            <Row gutter={[24, 16]}>
              <Col xs={{ span: 6 }}>
                <label>Número de manifiesto</label>
                <InputLayout
                  type="text"
                  placeholder="Ingresar numero de manifiesto"
                  size="large"
                  readOnly={view === "view"}
                  value={
                    view === "view"
                      ? serviceOrder?.manifestNumber
                      : manifest.value
                  }
                  onChange={manifest.onChange}
                />
                <ErrorMessage error={manifest.error}>
                  {manifest.error}
                </ErrorMessage>
              </Col>
              <Col xs={{ span: 6 }}>
                <DropdownSearch
                  onChange={handleSearch}
                  onSelect={handleSelect}
                  data={users}
                  loading={loadingUsers}
                  disabled={view === "view"}
                  selected={selectedUser}
                  removeSelectedUser={() => setSelectedUser(null)}
                />
                <ErrorMessage error={isSubmitting && !selectedUser}>
                  {isSubmitting &&
                    !selectedUser &&
                    "Por favor seleccione un conductor"}
                </ErrorMessage>
                {view !== "view" && (
                  <>
                    <TextDash
                      color="#00BE6D"
                      display="flex"
                      alignment="flex-end"
                    >
                      <FaPlusCircle />
                      <a href="#" onClick={modal.showModal}>
                        Agregar un conductor
                      </a>
                    </TextDash>
                    <TransporterForm modalSetting={modal} />
                  </>
                )}
              </Col>
              <Col xs={{ span: 4 }}>
                <label>Valor total del servicio</label>
                <CurrencyFormat
                  value={
                    view === "view" ? serviceOrder?.total : totalService?.value
                  }
                  customInput={InputLayout}
                  thousandSeparator={"."}
                  decimalSeparator=","
                  readOnly={view === "view"}
                  prefix={"$"}
                  size="large"
                  placeholder="$ 0.00"
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    totalService.setValue(floatValue);
                  }}
                />
                <ErrorMessage error={totalService.error}>
                  {totalService.error}
                </ErrorMessage>
              </Col>
              <Col xs={{ span: 4 }}>
                <label>Porcentaje de anticipo</label>
                <InputLayout
                  type="text"
                  placeholder="0"
                  maxLength={2}
                  suffix="%"
                  size="large"
                  readOnly={view === "view"}
                  max="100"
                  value={
                    view === "view"
                      ? `${serviceOrder?.advancePercentage}`
                      : advancePercentage.value
                  }
                  onChange={advancePercentage.onChange}
                />
                <ErrorMessage error={advancePercentage.error}>
                  {advancePercentage.error}
                </ErrorMessage>
              </Col>
            </Row>
            {view === "view" && (
              <>
                {serviceOrder?.status === "PAYMENT" && (
                  <RemaningPaymentModal
                    modalSetting={{
                      ...modalRemainingPayment,
                      title:
                        "Esta seguro que quiere generar el pago de este anticipo?",
                      handleSearch,
                      handleSelect,
                      users,
                      selectedUser,
                      setSelectedUser,
                      serviceOrder,
                      setServiceOrder,
                    }}
                  />
                )}
                <ServiceModal
                  modalSetting={{
                    ...modalService,
                    title: renderAdvanceTittle(),
                    onPress: onFirstPayment,
                    loadingAdvance,
                  }}
                />
              </>
            )}

            {view !== "create" && serviceOrderHistory()}

            {createBtns()}
          </>
        )}
      </DashboardCard>
    </>
  );
}

export default withRouter(ServiceOrders);
