import React from "react";
import {  ModalLayout } from "../styledComponents";

function DashboardModal({
  children,
  visible,
  handleOk,
  handleCancel,
  footer,
  title = "Agregar un conductor",
}) {
  return (
    <>
      <ModalLayout
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={footer}
      >
        {children}
      </ModalLayout>
    </>
  );
}

export default DashboardModal;
