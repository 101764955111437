import React, { useEffect, useState } from "react";
import {
  DashboardSectionTitle,
  DashboardCardTitle,
  Indicator,
  IconCardWrapper,
  DashboardCard,
} from "../../../components/dashboard-layout/styledComponents";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { Row, Col, DatePicker } from "antd";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import {
  MdAssignment,
  MdAssignmentTurnedIn,
  MdAttachMoney,
} from "react-icons/md";
import { columns } from "./tableConfig";
import { Link } from "react-router-dom";
import Button from "../../../components/dashboard-layout/button/Button";
import {
  getInvoiceTotal,
  getServiceOrdersCount,
  onGetServiceOrders,
} from "../../../Services/firebase";
import Spinner from "../../../components/dashboard-SA/Spinner";
import getRangeDates from "../../../utils/getRangeDates";
import moment from "moment";
const { RangePicker } = DatePicker;

export default function Home({ history }) {
  const { start, end } = getRangeDates();
  const [orderServices, setOrderServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTotals, setLoadingTotals] = useState(false);
  const [loadingCounts, setLoadingCounts] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [serviceOrderCount, setServiceOrderCount] = useState(null);
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const onChangePage = (page) => {
    setPage(page);
  };

  const onChangeItemsPerPage = (current, size) => {
    setItemsPerPage(size);
  };

  useEffect(() => {
    const getServiceOrders = async () => {
      try {
        setLoading(true);
        const { data } = await onGetServiceOrders(
          page,
          itemsPerPage,
          dateRange,
          searchVal
        );
        setOrderServices(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    const timeout = setTimeout(() => getServiceOrders(), 500);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [page, itemsPerPage, searchVal, dateRange]);

  useEffect(() => {
    const onGetServiceOrdersCount = async () => {
      try {
        setLoadingCounts(true);
        const { data } = await getServiceOrdersCount(start(), end());
        setServiceOrderCount(data);
        setLoadingCounts(false);
      } catch (error) {
        setLoadingCounts(false);

        console.log(error);
      }
    };

    const onGetInvoiceTotal = async () => {
      try {
        setLoadingTotals(true);
        const { data } = await getInvoiceTotal();
        setTotalInvoice(data.totalPendingInvoices);
        setLoadingTotals(false);
      } catch (error) {
        setLoadingTotals(false);

        console.log(error);
      }
    };
    onGetInvoiceTotal();
    onGetServiceOrdersCount();
    // eslint-disable-next-line
  }, [dateRange]);

  const onClickRow = (e, row) => {
    if (
      e.target.parentElement.className.includes("icons") ||
      e.target.tagName === "svg"
    ) {
      return;
    }
    history.push(`/service-order/${row.id}`);
  };

  const onChangeSearchBar = (e) => {
    setSearchVal(e.target.value);
  };
  return (
    <React.Fragment>
      <Row align="middle">
        <Col span={12}>
          <DashboardSectionTitle>Dashboard</DashboardSectionTitle>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
        >
          <DashboardCard minheight="143px">
            <section>
              <DashboardCardTitle>Solicitudes creadas</DashboardCardTitle>
              <h2>
                {loadingCounts ? <Spinner /> : serviceOrderCount?.createdCount}
              </h2>
              <Indicator color="#00BE6D">
                <FaLongArrowAltUp />
                5%
              </Indicator>
              <span>desde el último mes</span>
            </section>
            <aside>
              <IconCardWrapper color="#F84B05" background="#F84B0512">
                <MdAssignment />
              </IconCardWrapper>
            </aside>
          </DashboardCard>
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
        >
          <DashboardCard minheight="143px">
            <section>
              <DashboardCardTitle>Solicitudes Completadas</DashboardCardTitle>
              <h2>
                {loadingCounts ? <Spinner /> : serviceOrderCount?.finishedCount}
              </h2>
              <Indicator color="#F84B05">
                <FaLongArrowAltDown />
                1.02%
              </Indicator>
              <span>desde la ultima semana</span>
            </section>
            <aside>
              <IconCardWrapper color="#00BE6D" background="#E5F9F0">
                <MdAssignmentTurnedIn />
              </IconCardWrapper>
            </aside>
          </DashboardCard>
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
        >
          <DashboardCard minheight="143px">
            <section>
              <DashboardCardTitle>Valor a pagar hoy a Hoy+</DashboardCardTitle>
              <h2>
                {loadingTotals ? (
                  <Spinner />
                ) : (
                  `$ ${new Intl.NumberFormat("de-DE").format(totalInvoice)}`
                )}
              </h2>
              <Indicator color="#00BE6D">
                <FaLongArrowAltUp />
                5%
              </Indicator>
              <span>desde el último mes</span>
            </section>
            <aside>
              <IconCardWrapper color="#1D00FF" background="#4605F812">
                <MdAttachMoney />
              </IconCardWrapper>
            </aside>
          </DashboardCard>
        </Col>
        {/* <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 6 }}
        >
          <DashboardCard minheight="143px">
            <section>
              <DashboardCardTitle>Fecha oportuna de pago</DashboardCardTitle>
              <h2>20/07/2020</h2>
            </section>
            <aside>
              <IconCardWrapper color="#00C4FF" background="#00C4FF17">
                <MdToday />
              </IconCardWrapper>
            </aside>
          </DashboardCard>
        </Col> */}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <DashboardTable
            columns={columns}
            tableTitle="Solicitudes de servicio"
            data={orderServices.data}
            pagination={orderServices.pagination}
            rowkey="key"
            page={page}
            loading={loading}
            onChangePage={onChangePage}
            onChangeItemsPerPage={onChangeItemsPerPage}
            onClickRow={onClickRow}
            onChangeSearchBar={onChangeSearchBar}
            customButton={
              <Button type="primary">
                <Link to={"/service-order/create"}>
                  {"+ Crear Solicitud de Servicio"}
                </Link>
              </Button>
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
