import styled from "styled-components";
import { Breadcrumb, Select } from "antd";

export const WithdrawalDetailLayout = styled.div`
  & .withdrawal-amount {
    margin-top: 10px;
  }

  & .amount-status-container {
    & div {
      width: 100%;
    }
  }

  & .payment-status {
    background-color: #f84b05;
    color: white;
  }

  & .collapse-container {
    margin-top: 30px;

    & .ant-collapse-header {
      background-color: #f3f6fb;
      font-weight: bolder !important;
      color: #0a181d;
      padding-left: 16px;
    }
  }
  &
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 95%;
  }

  & .file-container {
    & h4 {
      background-color: #f3f6fb;
      padding: 12px;
      margin-bottom: 16px;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      margin-top: 30px;
      font-weight: bolder;
      color: #0a181d !important;
    }

    & .download-receipt {
      color: #20bea5;
      display: block;
      text-align: right;
    }

    & .file-name {
      margin-left: 16px;
    }
  }
`;

export const UploadReceiptLayout = styled.div`
  & h4 {
    background-color: #f3f6fb;
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    margin-top: 30px;
    font-weight: bolder;
    color: #0a181d !important;
  }

  & .upload-receipt-component {
    height: 200px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #f3f6fb;
    border: 3px #e3e3e3 dashed;
    position: relative;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    & input[type="file"] {
      height: 200px;
      width: 100%;
      z-index: 1;
      opacity: 0;
      position absolute;
      outline: 0;
      cursor: pointer;

    }

    & p {
      text-align: center;
      font: normal normal medium 14px/14px Aeonik;
      letter-spacing: 0px;
      color: #607384;
    }

    & span {
      text-align: center;
      font: normal normal medium 14px/14px Aeonik;
      letter-spacing: 0px;
      color: #6E8091B3;
      margin-top: 10px;
    }

    & button {
      background-color: #1CA892;
      border-radius: 4px;
      outline: 0;
      border: none;
      padding: 10px;
      color: white;
    }
  }
`;

export const DashboardSaCard = styled.div`
  width: 100%;
  height: ${(props) => props.height || "559px"};
  border-radius: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000d;
  padding: 50px 60px;
  display: flex;
  flex-direction: column;
`;
export const BreadcrumItem = styled(Breadcrumb.Item)`
  color: ${(props) => (props.active && `#051A55 !important`) || `#76889A`};
  font-weight: ${(props) => props.active && "bold"};
  font-size: 14px;
`;

export const SelectStatus = styled(Select)`
  color: white;
  border: none;
  border-radius: 4px;
  & .ant-select-selector {
    background: ${(props) =>
        props.currentStatus === "COMPLETED" ? "#00BE6D" : "#f84b05"}
      0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    border: none !important;
    outline: 0 !important;
    padding: 4px;
    &:focus {
      border: none !important;
      outline: 0;
    }
  }

  & .ant-select-arrow {
    color: white;
  }
`;
