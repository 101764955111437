import React, { useState, useEffect } from "react";
import DashboardTable from "../../../components/dashboard-layout/table/DashboardTable";
import { columns } from "./tableConfig";
import {
  getEfectyTransactions,
  getExternalEntities,
  getWalletBalance,
} from "../../../Services/firebase";
import { DashboardSectionTitle } from "../../../components/dashboard-layout/styledComponents";
import { useHistory } from "react-router-dom";
import DashboardItem from "../../../components/dashboard-SA/DashboardItem";
import { Row, Col, DatePicker } from "antd";
import { MdAttachMoney } from "react-icons/md";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function EfectyList() {
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [filter, setFilter] = useState("");
  const [walletBalance, setWalletBalance] = useState(null);
  const [dateRange, setDateRange] = useState([
    moment().subtract(30, "days").startOf("day").format(),
    moment().endOf("day").format(),
  ]);
  const history = useHistory();
  const onChangePage = (page) => {
    setPagination({ ...pagination, page });
  };

  const onChangeFilter = (e) => {
    setFilter(e.target.value);
  };

  const onGetWalletBalance = async () => {
    setLoadingBalance(true);
    try {
      const responseEntities = await getExternalEntities(1, 10);
      const efectyType = responseEntities.data.data.filter(
        (elm) => elm.type === "EFECTY"
      )[0];
      const response = await getWalletBalance(
        efectyType?.id || 1,
        "EXTERNAL_ENTITYS"
      );
      setWalletBalance(response?.data?.availableBalance);
      setLoadingBalance(false);
    } catch (error) {
      setLoadingBalance(false);
    }
  };

  const onGetEfectyTransactions = async () => {
    setLoading(true);
    try {
      const { data } = await getEfectyTransactions(
        pagination.page,
        pagination.pageSize,
        dateRange
      );
      const formatedData = data.paymentIntents.map((elm) => ({
        id: elm.id,
        createdAt: elm.createdAt || "-",
        paidAt: elm.paidAt || "-",
        status: elm?.status,
        user: elm?.User,
        idNumber: elm?.User?.Driver?.idNumber || "-",
        value: elm?.value,
      }));
      setTransactions(formatedData);
      setPagination({
        ...pagination,
        page: data.pagination.currentPage,
        total: data.pagination.totalItems,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetWalletBalance();
  }, []);
  useEffect(() => {
    const timeout = setTimeout(onGetEfectyTransactions, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [pagination.page, pagination.pageSize, filter, dateRange]);
  return (
    <>
      <Row>
        <Col span={8}>
          <DashboardSectionTitle>
            Solicitudes de Retiro Efecty
          </DashboardSectionTitle>
        </Col>
        <Col span={16}>
          <Row justify="end">
            <RangePicker
              defaultValue={[moment().subtract(30, "days"), moment()]}
              ranges={{
                Hoy: [moment(), moment()],
                "Mes en curso": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                Semana: [moment().startOf("week"), moment().endOf("week")],
                "Últimos 30 días": [moment().subtract(30, "days"), moment()],
              }}
              onChange={(dates) =>
                setDateRange([
                  moment(dates[0]).startOf("day").format(),
                  moment(dates[1]).endOf("day").format(),
                ])
              }
            />
          </Row>
          <Row justify="end">
            <Col span={10}>
              <DashboardItem
                title="Saldo restante en efecty"
                value={`$ ${new Intl.NumberFormat("de-DE").format(
                  walletBalance
                )}`}
                factor=""
                icon={<MdAttachMoney />}
                iconColor="#1CA892"
                iconBg="#1CA89212"
                factorStatus="up"
                link={{
                  url: "/efecty/payments",
                  color: "#1CA892",
                  text: "Ver pagos",
                }}
                loading={loadingBalance}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <DashboardTable
        columns={columns}
        data={transactions}
        pagination={pagination}
        page={pagination.page}
        loading={loading}
        onChangePage={onChangePage}
        tableTitle="Lista de solicitudes"
        searchbarPlaceholder="Buscar solicitud de retiro"
        onChangeSearchBar={onChangeFilter}
        onClickRow={(text, record) => {
          history.push(`/efecty/${record.id}`);
        }}
      />
    </>
  );
}
