import React, {useState} from "react";
import DashboardModal from "../../../components/dashboard-layout/modal/DashboardModal";
import { Row, Col, Spin } from "antd";
import { InputLayout } from "../../../components/dashboard-layout/styledComponents";
import { LabelText, ButtonsContainer } from "../Clients/NewClient";
import { Select } from "antd";
import styled from "styled-components";
import Button from "../../../components/dashboard-layout/button/Button";
import { commissionTypes } from "../../../utils/constants";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "../../../components/auth-layout/styledComponents";
import { createProduct } from "../../../Services/firebase";
import { toast } from "react-toastify";
import {useParams} from "react-router-dom";

const { Option } = Select;

const ProductForm = ({ modalSetting, handleAfterProductCreation }) => {
  const { control, handleSubmit, errors, setValue, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const onSubmit = async (d) => {
    const productData = {
      ...d,
      value: d.commissionValue,
      partnerId: params.id
    }
    setLoading(true);
    try {
      const response = await createProduct(productData);
      toast.success("Se ha creado el producto satisfactoriamente");
      setLoading(false);
      reset();
      handleAfterProductCreation();
    } catch (e) {
      console.log(e);
      toast.error(e?.details?.message || "Ha ocurrido un error inesperado");
      setLoading(false);
    }
  };

  const clearValues = (e) => {
    e.preventDefault();
    reset();
  };
  return (
    <DashboardModal {...modalSetting} title="Agregar producto" footer={null}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col style={{ width: 270 }}>
            <LabelText>Nombre del producto</LabelText>
            <Controller
              as={InputLayout}
              name="name"
              control={control}
              defaultValue=""
              placeholder="Ingresar nombre"
              readOnly={loading}
              size="large"
              type="text"
              rules={{
                required: "Este campo es requerido.",
                maxLength: {
                  value: 45,
                  message: "Máximo 45 caracteres permitidos",
                },
              }}
            />
            {errors?.name && (
              <ErrorMessage error={1}>{errors.name?.message}</ErrorMessage>
            )}
          </Col>
        </Row>
        <Row justify="space-between">
          <Col lg={{ span: 12 }}>
            <LabelText>Unidad</LabelText>

            <Controller
              as={
                <SelectInput>
                  {commissionTypes.map((elm, index) => (
                    <Option key={index} value={elm.type}>
                      {elm.label}
                    </Option>
                  ))}
                </SelectInput>
              }
              name="commissionType"
              control={control}
              defaultValue=""
              placeholder="Ingresar nombre"
              readOnly={loading}
              defaultValue="PERCENT_PER_TOTAL"
              rules={{
                required: "Este campo es requerido.",
              }}
            />
            {errors?.commissionType && (
              <ErrorMessage error={1}>
                {errors.commissionType?.message}
              </ErrorMessage>
            )}
          </Col>
        </Row>
        <Row style={{ flex: 1 }}>
          <Col>
            <LabelText>Comisión</LabelText>
            <Controller
              as={InputLayout}
              name="commissionValue"
              control={control}
              defaultValue=""
              type="number"
              placeholder="Ingrese la comisión"
              readOnly={loading}
              size="large"
              suffix="%"
              rules={{
                required: "Este campo es requerido.",
              }}
            />
            {errors?.commissionValue && (
              <ErrorMessage error={1}>
                {errors.commissionValue?.message}
              </ErrorMessage>
            )}
          </Col>
        </Row>
        <ButtonsContainer style={{ marginTop: 32 }}>
          {!loading ? (
            <>
              {" "}
              <Button onClick={(e) => clearValues(e)} type={"alternative"}>
                Cancelar
              </Button>
              <Button type={"submit"}>Agregar</Button>{" "}
            </>
          ) : (
            <Spin />
          )}
        </ButtonsContainer>
      </form>
    </DashboardModal>
  );
};

const SelectInput = styled(Select)`
  .ant-select-selector {
    padding: 6.5px 11px !important;
    height: 40px !important;
    background: #76889a0d !important;
    border: 1px solid #76889a81 !important;
    border-radius: 5px !important;
  }
`;

export default ProductForm;
